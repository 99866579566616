// ----------------------------------------------------------
// Contains all SASS & CSS constants.
// ----------------------------------------------------------


// -------------------------
// 0. Config
// -------------------------

/* Global */
// $grid-columns: 12; // total columns
// $grid-gutter: 24px; // gap between columns

// /* Breakpoints */
// $breakpoints: (
//   small: 40rem,
//   medium: 67.5rem,
//   large: 80rem,
//  ) !default;


/* Colors */
$color-primary: #ED603A !default; //ffaa8e
$color-primary--variant: black !default;
$color-primary--on: black !default;

$color-secondary: red !default;
$color-secondary--variant: red !default;
$color-secondary--on: white !default;

$color-background--on: #E9E5E2 !default;
$color-background: #010101 !default; // #01030A

$color-notice: #3498db;
$color-notice--on: white;

$color-success: #1abc9c;
$color-success--on: white;

$color-error :#e74c3c;
$color-error--on: white;

$color-black: black;
$color-white: white;

$color-black--50: rgba(0, 0, 0, .5);
$color-black--16: rgba(0, 0, 0, .16);
$color-white--80: rgba(255, 255, 255, .80);
$color-white--50: rgba(255, 255, 255, .5);
$color-white--16: rgba(255, 255, 255, .16);
$color-white--8: rgba(255, 255, 255, .08);
$color-white--4: rgba(255, 255, 255, .04);

$color-gray--dark: #666;
$color-gray--light: #999;

// $magenta: #FF9BCB;

// $color-accent: coral !default;
// $color-text-100: #FFF !default;
// $color-text-500: #303030 !default;

// Extra misc
$radius: 20px;
