@import url("https://use.typekit.net/fkk1xlx.css");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
/* Box sizing rules  */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0 none;
  outline: 0;
  list-style: none;
  font-size: 100%;
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

/* Remove list styles */
ol[role=list],
ul[role=list] {
  list-style: none;
}

/* Remove list padding if list is part of a nav */
nav ul,
nav ol {
  padding: 0;
}

/* Set body rules */
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Set image and video rules */
img,
svg,
picture,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Inherit fonts */
input,
button,
textarea,
select {
  font: inherit;
}

hr {
  height: 0;
  box-sizing: content-box;
}

button,
input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
}

button,
input,
input[type=button],
input[type=reset],
input[type=submit],
select {
  appearance: none;
  border-radius: 0;
}

button[disabled],
input[disabled] {
  cursor: default;
}

/* Global */
/* Colors */
@keyframes animHandWave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
@keyframes animFocusBlurInOut {
  0% {
    filter: blur(0px);
  }
  25% {
    filter: blur(4px);
  }
  100% {
    filter: blur(0px);
  }
}
@keyframes animFocusBlurOut {
  0% {
    filter: blur(8px);
  }
  25% {
    filter: blur(4px);
  }
  50% {
    filter: blur(0px);
  }
  75% {
    filter: blur(2px);
  }
  100% {
    filter: blur(0px);
  }
}
@keyframes animArrowUp {
  0% {
    transform: translate(0);
  }
  to {
    transform: translate(100%, -100%);
  }
}
@keyframes animArrowDown {
  0% {
    transform: translate(-100%, 100%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes shadowPulse {
  0% {
    box-shadow: 0 0 12px 0 #73E753;
    /* Begin met een kleinere blur */
  }
  50% {
    box-shadow: 0 0 20px 1px #73E753;
    /* Vergroot de blur naar een grotere waarde */
  }
  100% {
    box-shadow: 0 0 12px 0 #73E753;
    /* Keer terug naar de oorspronkelijke waarde */
  }
}
/* Lenis recommendation */
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

:root {
  --color-background: #010101;
  --color-text: #E9E5E2;
  --color-text--50: rgba(255, 255, 255, 0.5);
  --color-text--16: rgba(255, 255, 255, 0.16);
  --color-invert: invert(0);
}

body {
  background-color: var(--color-background);
  color: var(--color-text);
}
body.use-cursor * {
  cursor: default;
}
body.use-cursor * a {
  cursor: pointer;
}
body.no-scroll {
  overflow-y: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 576px) {
  body.no-scroll {
    position: relative;
  }
}
body.no-scroll-y {
  overflow-y: hidden;
  height: 100%;
  width: 100%;
}
body[data-theme=light] .parallax,
body[data-theme=light] .videoplayer-wrapper, body[data-theme=dark] .parallax,
body[data-theme=dark] .videoplayer-wrapper {
  color: white;
}
body[data-theme=light] {
  --color-background: #E9E5E2;
  --color-text: #010101;
  --color-text--50: rgba(0, 0, 0, 0.5);
  --color-text--16: rgba(0, 0, 0, 0.16);
  --color-invert: invert(1);
  transition: all 0.5 linear;
}
body[data-theme=light] #site-footer::after {
  background: radial-gradient(circle closest-corner at bottom, #E95D2B, transparent);
}
body[data-theme=dark] {
  --color-background: #010101;
  --color-text: #E9E5E2;
  --color-text--50: rgba(255, 255, 255, 0.5);
  --color-text--16: rgba(255, 255, 255, 0.16);
  --color-invert: invert(0);
  transition: all 0.5 linear;
}
body[data-theme=dark] #site-footer::after {
  background: radial-gradient(circle closest-corner at bottom, rgba(0, 37, 255, 0.72), transparent);
}

section {
  position: relative;
}
@media screen and (min-width: 0) {
  section {
    padding: 80px 0;
  }
}
@media screen and (min-width: 768px) {
  section {
    padding: 160px 0;
  }
}
section .row:not(:last-child) {
  margin-bottom: 64px;
}

.gallery-card {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.gallery-card a .gallery-card--media > *,
.gallery-card a .gallery-card--caption > * {
  cursor: pointer;
}
.gallery-card a .gallery-card--caption h4 .arrow {
  height: 20px;
  width: 20px;
  overflow: hidden;
  position: relative;
  transition: 250ms ease-in-out;
  display: inline-block;
  vertical-align: middle;
}
.gallery-card a .gallery-card--caption h4 .arrow::before, .gallery-card a .gallery-card--caption h4 .arrow::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/icons/icon-arrow-diagonal.svg");
  background-repeat: no-repeat;
  background-size: cover;
  filter: var(--color-invert);
}
.gallery-card a .gallery-card--caption h4 .arrow::after {
  transform: translate(-100%, 100%);
}
.gallery-card:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 0) {
  .gallery-card {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) {
  .gallery-card:nth-last-child(-n+3) {
    margin-bottom: 0;
  }
}

.featured .gallery-card:not(:has(a)) .gallery-card--caption {
  opacity: 0.7;
}
.featured .gallery-card a:hover img {
  transform: scale(1.025);
}
.featured .gallery-card a:hover .media--video::after {
  opacity: 0;
}
.featured .gallery-card a:hover .gallery-card--caption .arrow::before, .featured .gallery-card a:hover .gallery-card--caption .arrow::after {
  animation-duration: 0.35s;
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-delay: 2ms;
}
.featured .gallery-card a:hover .gallery-card--caption .arrow::before {
  animation-name: animArrowDown;
}
.featured .gallery-card a:hover .gallery-card--caption .arrow::after {
  animation-name: animArrowUp;
}

.gallery-card--media {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}
.gallery-card--media img {
  position: relative;
  z-index: 1;
  transition: all 0.5s ease;
}
.gallery-card--media video {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: inherit;
  padding-right: inherit;
  z-index: 0;
}
.gallery-card--media.media--video img:hover {
  opacity: 0;
}
.gallery-card--media.media--video::after {
  height: 24px;
  width: 24px;
  transition: all 0.5s ease;
  content: "";
  position: absolute;
  top: 16px;
  right: 24px;
  z-index: 1;
  background-image: url("../images/icons/icon-play.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.gallery-card--caption {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;
}
.gallery-card--caption h4,
.gallery-card--caption h5 {
  margin-bottom: 0 !important;
}
.gallery-card--caption h5 {
  display: inherit;
  align-items: center;
  transition: all 0.5s ease;
  text-transform: initial;
}

.marquee {
  overflow: hidden;
}
.marquee .marquee-track {
  display: flex;
  flex-direction: row;
}
.marquee .marquee-track .marquee-item {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-wrap: nowrap;
}
@media screen and (min-width: 0) {
  .marquee .marquee-track .marquee-item {
    margin-left: 32px;
  }
}
@media screen and (min-width: 768px) {
  .marquee .marquee-track .marquee-item {
    margin-left: 64px;
  }
}
.marquee .marquee-track .marquee-item .marquee-item--title > * {
  line-height: 1.2;
}
@media screen and (min-width: 0) {
  .marquee .marquee-track .marquee-item .marquee-item--title > * {
    font-size: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .marquee .marquee-track .marquee-item .marquee-item--title > * {
    font-size: 8.5rem;
  }
}
@media screen and (min-width: 0) {
  .marquee .marquee-track .marquee-item .marquee-item--title + .marquee-item--icon {
    margin-left: 32px;
  }
}
@media screen and (min-width: 768px) {
  .marquee .marquee-track .marquee-item .marquee-item--title + .marquee-item--icon {
    margin-left: 64px;
  }
}
.marquee .marquee-track .marquee-item .marquee-item--icon {
  background-color: #ED603A;
  color: black;
  border-radius: 100%;
  background-image: url("../images/icons/icon-play-black.svg");
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: middle;
}
@media screen and (min-width: 0) {
  .marquee .marquee-track .marquee-item .marquee-item--icon {
    height: 64px;
    width: 64px;
    background-size: 16px;
    margin: 0 auto;
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .marquee .marquee-track .marquee-item .marquee-item--icon {
    height: 120px;
    width: 120px;
    background-size: 32px;
  }
}

.parallax {
  position: relative;
}
.parallax .parallax-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999;
}
.parallax .parallax-container .parallax-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 120px);
  min-height: 100vh;
  background-image: url("../images/cb-profile-1.jpg");
  background-size: cover;
  background-position: center;
  transform: translateZ(0);
  z-index: -1;
  will-change: transform;
  transition: transform 0.2s;
}
.parallax .content {
  text-align: center;
}

.filmrol {
  padding: 80px 0;
  overflow: hidden;
  display: none;
}
@media screen and (min-width: 576px) {
  .filmrol {
    padding: 32px 0;
  }
}
.filmrol::after {
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, #01030A 0%, rgba(1, 3, 10, 0) 8%, rgba(1, 3, 10, 0) 92%, #01030A 100%);
  position: absolute;
  top: 0;
  left: 0;
}
.filmrol .container-fluid {
  padding: 0;
}

.roll {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  position: relative;
  background-color: #101010;
  border: 1px solid rgba(255, 255, 255, 0.16);
}
.roll.album {
  padding: 8px;
  border-radius: 16px;
}
.roll.album li {
  flex: 1;
  border: 2px solid #101010;
}
.roll.album li:nth-child(5) {
  filter: url(#round);
  content: "";
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 20%, 75% 0);
}
@media screen and (min-width: 576px) {
  .roll.album li:nth-last-child(-n+3) {
    display: none;
  }
}
.roll.album li.cta {
  position: relative;
  border: none;
}
.roll.album li.cta::after {
  height: 48px;
  width: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-image: url("../images/icons/icon-arrow-diagonal.svg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: 250ms ease-in-out;
}
.roll::after {
  height: 40px;
  width: 100%;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-image: url("../images/filmrol-start.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}
@media screen and (min-width: 576px) {
  .roll::after {
    height: 44px;
    width: 220px;
  }
}
.roll li {
  flex: 0 0 auto;
  width: 25%;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 576px) {
  .roll li {
    width: 40%;
  }
}
.roll li::after {
  height: 32px;
  width: 112px;
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  background-image: url("../images/film-code.png");
  background-repeat: no-repeat;
  background-size: 100%;
  mix-blend-mode: difference;
  display: none;
}
@media screen and (min-width: 576px) {
  .roll li::after {
    height: 16px;
    width: 56px;
  }
}
.roll li img {
  display: block;
  height: 100%;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
}

.cursor {
  pointer-events: none;
  display: none;
}
@media screen and (min-width: 768px) {
  .cursor {
    height: 10px;
    width: 10px;
    display: block;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 50%;
    mix-blend-mode: difference;
    transform: translate(-50%, -50%);
    transition: width 500ms, height 500ms;
    z-index: 999;
  }
  .cursor.grow {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  .cursor.grow--big, .cursor.grow--alt {
    width: 96px;
    height: 96px;
  }
}

.filmcode {
  background-image: url("../images/filmcode-1.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  filter: var(--color-invert);
}
@media screen and (min-width: 0) {
  .filmcode {
    height: 24px;
  }
}
@media screen and (min-width: 768px) {
  .filmcode {
    height: 32px;
    margin-top: 24px;
  }
}
.filmcode.small {
  height: 24px;
  width: 104px;
  display: inline-block;
}

.paging {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

button {
  padding: 1rem 2rem 1rem 2rem;
  border: none;
  background: #555;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 9999;
  cursor: pointer;
}
button.prev {
  position: absolute;
  top: 10%;
  left: 15%;
}
button.next {
  position: absolute;
  top: 10%;
  right: 15%;
}
button.toggle {
  position: absolute;
  top: 10%;
}

.interactive-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.interactive-bg canvas {
  display: block;
  opacity: 0.5;
}

.focus {
  animation: animFocusBlurOut 1.75s ease-in-out;
}

.glossy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(100px);
  pointer-events: none;
}

.overflow {
  overflow: hidden;
}

.p0 {
  padding: 0;
}

.pt0 {
  padding-top: 0;
}

.pr0 {
  padding-right: 0;
}

.pb0 {
  padding-bottom: 0;
}

.pl0 {
  padding-left: 0;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-center {
  text-align: center;
}

.txt-justify {
  text-align: justify;
}

.txt-nowrap {
  white-space: nowrap;
}

.txt-lowercase {
  text-transform: lowercase;
}

.txt-uppercase {
  text-transform: uppercase;
}

.txt-capitalize {
  text-transform: capitalize;
}

.zindex-1 {
  z-index: 1;
}

.zindex-2 {
  z-index: 2;
}

.zindex-3 {
  z-index: 3;
}

.zindex-4 {
  z-index: 4;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.left {
  float: left;
}

.center {
  margin: 0 auto;
  display: inline-block;
  float: none;
  text-align: center;
}

.right {
  float: right;
}

.inline {
  display: inline;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

.mt-none {
  margin-top: 0;
}

.mt-quarter {
  margin-top: 10px;
}

.mt-halve {
  margin-top: 20px;
}

.mt-one {
  margin-top: 40px;
}

.mt-two {
  margin-top: 80px;
}

.mt-three {
  margin-top: 120px;
}

.mt-four {
  margin-top: 160px;
}

.mb-none {
  margin-bottom: 0;
}

.mb-quarter {
  margin-bottom: 10px;
}

.mb-halve {
  margin-bottom: 20px;
}

.mb-one {
  margin-bottom: 40px;
}

.mb-two {
  margin-bottom: 80px;
}

.mb-three {
  margin-bottom: 120px;
}

.mb-four {
  margin-bottom: 160px;
}

.mr-none {
  margin-right: 0;
}

.mr-quarter {
  margin-right: 10px;
}

.mr-halve {
  margin-right: 20px;
}

.mr-one {
  margin-right: 40px;
}

.mr-three {
  margin-right: 120px;
}

.mr-four {
  margin-right: 160px;
}

.ml-none {
  margin-left: 0;
}

.ml-quarter {
  margin-left: 10px;
}

.ml-halve {
  margin-left: 20px;
}

.ml-one {
  margin-left: 40px;
}

.ml-two {
  margin-left: 80px;
}

.ml-three {
  margin-left: 120px;
}

.ml-four {
  margin-left: 160px;
}

.margin-none {
  margin: 0;
}

.margin-quarter {
  margin: 10px;
}

.margin-halve {
  margin: 20px;
}

.margin-one {
  margin: 40px;
}

.margin-two {
  margin: 80px;
}

.margin-three {
  margin: 120px;
}

.margin-four {
  margin: 160px;
}

.radius {
  border-radius: 8px;
}
@media screen and (min-width: 576px) {
  .radius {
    border-radius: 4px;
  }
}

.radius-top {
  border-radius: 8px 8px 0 0;
}

.radius-right {
  border-radius: 0 8px 8px 0;
}

.radius-bottom {
  border-radius: 0 0 8px 8px;
}

.radius-left {
  border-radius: 8px 0 0 8px;
}

.radius {
  overflow: hidden;
}

.anim--handwave {
  animation: animHandWave 2.5s infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

/* set base values */
html {
  -webkit-font-smoothing: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
@media screen and (min-width: 0) {
  h1:not(:last-child),
  h2:not(:last-child),
  h3:not(:last-child),
  h4:not(:last-child),
  h5:not(:last-child),
  h6:not(:last-child) {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  h1:not(:last-child),
  h2:not(:last-child),
  h3:not(:last-child),
  h4:not(:last-child),
  h5:not(:last-child),
  h6:not(:last-child) {
    margin-bottom: 32px;
  }
}

h1.display--large, h1.display--medium, h1.display--small,
h2.display--large,
h2.display--medium,
h2.display--small,
h3.display--large,
h3.display--medium,
h3.display--small {
  font-family: "Anton", sans-serif;
  font-weight: unset;
  text-transform: uppercase;
  line-height: 1;
}
@media screen and (min-width: 0) {
  h1.display--large,
  h2.display--large,
  h3.display--large {
    font-size: 120px;
  }
}
@media screen and (min-width: 768px) {
  h1.display--large,
  h2.display--large,
  h3.display--large {
    font-size: 240px;
  }
}
@media screen and (min-width: 0) {
  h1.display--medium,
  h2.display--medium,
  h3.display--medium {
    font-size: 64px;
  }
}
@media screen and (min-width: 768px) {
  h1.display--medium,
  h2.display--medium,
  h3.display--medium {
    font-size: 120px;
  }
}
@media screen and (min-width: 0) {
  h1.display--small,
  h2.display--small,
  h3.display--small {
    font-size: 52px;
  }
}
@media screen and (min-width: 768px) {
  h1.display--small,
  h2.display--small,
  h3.display--small {
    font-size: 104px;
  }
}

h1 {
  line-height: 1.2;
  font-weight: 700;
}
@media screen and (min-width: 0) {
  h1 {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 5rem;
  }
}

h2 {
  font-family: "Anton", sans-serif;
  line-height: 1;
  text-transform: uppercase;
}
@media screen and (min-width: 0) {
  h2 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 768px) {
  h2 {
    font-size: 4.5rem;
  }
}

h3 {
  font-family: "Anton", sans-serif;
  line-height: 1;
  margin-bottom: 20px;
  text-transform: uppercase;
}
@media screen and (min-width: 0) {
  h3 {
    font-size: 2rem;
  }
}
@media screen and (min-width: 768px) {
  h3 {
    font-size: 3rem;
  }
}

h4 {
  font-weight: bold;
}
@media screen and (min-width: 0) {
  h4 {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 768px) {
  h4 {
    font-size: 1.25rem;
  }
}

h5 {
  line-height: 1.2;
  text-transform: uppercase;
  color: #666;
  margin-bottom: 20px;
}
@media screen and (min-width: 0) {
  h5 {
    font-size: 0.75rem;
  }
}
@media screen and (min-width: 768px) {
  h5 {
    font-size: 1rem;
  }
}

h6 {
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #999;
}

body,
p,
a {
  font-family: "proxima-nova", sans-serif;
  font-size: 1em;
  line-height: 1.5;
}

p:not(:only-of-type):not(:last-of-type),
a:not(:only-of-type):not(:last-of-type) {
  margin-bottom: 24px;
}
@media screen and (min-width: 0) {
  p.body--large,
  a.body--large {
    font-size: 1.25rem;
    line-height: 24px;
  }
}
@media screen and (min-width: 768px) {
  p.body--large,
  a.body--large {
    font-size: 1.5rem;
    line-height: 32px;
  }
}
@media screen and (min-width: 0) {
  p.body--medium,
  a.body--medium {
    font-size: 1rem;
    line-height: 24px;
  }
}
p.body--small,
a.body--small {
  font-size: 0.75rem;
  line-height: 16px;
}

.container,
.container-fluid {
  width: 96%;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 676px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 868px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1092px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1300px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1500px;
  }
}

.container-fluid {
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
}

.col {
  flex: 1 0 0%;
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}
.col span {
  background-color: red;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.col-xs-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-3 {
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-9 {
  flex: 0 0 75%;
  max-width: 75%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
  padding-left: 12px;
  padding-right: 12px;
}

.col-xs-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 992px) {
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1200px) {
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 1400px) {
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    padding-left: 12px;
    padding-right: 12px;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.col-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 576px) {
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-xl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-xl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-xl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-xl-offset-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-xxl-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-xxl-offset-3 {
    margin-left: 25%;
  }
  .col-xxl-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-xxl-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-xxl-offset-6 {
    margin-left: 50%;
  }
  .col-xxl-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-xxl-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-xxl-offset-9 {
    margin-left: 75%;
  }
  .col-xxl-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-xxl-offset-11 {
    margin-left: 91.6666666667%;
  }
}
.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

header#site-header {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media screen and (min-width: 768px) {
  header#site-header {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
header#site-header.invert {
  filter: invert(1);
}
header#site-header.sticky {
  position: relative;
}
@media screen and (min-width: 768px) {
  header#site-header.sticky {
    position: fixed;
    z-index: 2;
  }
}
header#site-header .logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header#site-header .logo .header-logo {
  display: inline-block;
  filter: blur(0);
  transition: filter 0.5s ease;
}
header#site-header .logo .header-logo:hover {
  animation: animFocusBlurInOut 1s ease;
}
header#site-header .logo .header-logo .logo--icon,
header#site-header .logo .header-logo .logo--type {
  display: inline-block;
  vertical-align: middle;
}
header#site-header .logo .header-logo .logo--icon img {
  height: 24px;
  filter: var(--color-invert);
}
@media screen and (min-width: 768px) {
  header#site-header .logo .header-logo .logo--icon img {
    height: auto;
  }
}
header#site-header .logo .header-logo .logo--type {
  display: none;
}
@media screen and (min-width: 768px) {
  header#site-header .logo .header-logo .logo--type {
    display: inline-block;
    margin-left: 8px;
  }
  header#site-header .logo .header-logo .logo--type span {
    line-height: 16px;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
  }
}
header#site-header .header-logo {
  z-index: 999;
}
header#site-header .navigation {
  z-index: 998;
}
header#site-header .status,
header#site-header .mode-toggler {
  display: none;
}
@media screen and (min-width: 768px) {
  header#site-header .status,
  header#site-header .mode-toggler {
    display: inline-flex;
    align-items: center;
    padding: 6px 16px 6px 12px;
    border: 1px solid var(--color-text--16);
    border-radius: 360px;
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(8px);
    transition: 250ms ease-in-out;
  }
}
header#site-header .status {
  gap: 8px;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  header#site-header .status {
    display: inline-flex;
  }
}
header#site-header .status:hover {
  background: rgba(255, 255, 255, 0.08);
}
header#site-header .status .status-icon,
header#site-header .status p {
  cursor: inherit;
}
header#site-header .status .status-icon {
  aspect-ratio: 1/1;
  overflow: hidden;
  position: relative;
  background-color: #999;
  width: 6px;
  border-radius: 360px;
  will-change: transform;
}
header#site-header .status.available .status-icon {
  background-color: #73E753;
  box-shadow: 0 0 12px 0 #73E753;
  animation: shadowPulse 2s infinite ease-in-out;
}
header#site-header .status.non-available .status-icon {
  background-color: #e75353;
  box-shadow: 0 0 12px 0 #e75353;
}
header#site-header .mode-toggler,
header#site-header .mode-toggler {
  cursor: pointer;
}
header#site-header .mode-toggler:hover {
  background: var(--color-text--16);
}
header#site-header .mode-toggler .mode-message {
  display: none;
}
@media screen and (min-width: 768px) {
  header#site-header .mode-toggler .mode-message,
  header#site-header .mode-toggler .mode-icon {
    display: inline-block;
    vertical-align: middle;
  }
}
header#site-header .mode-toggler .mode-icon {
  height: 16px;
  width: 16px;
  background-image: url("../images/icons/icon-state-bluehour.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  vertical-align: middle;
  cursor: pointer;
  filter: var(--color-invert);
}
@media screen and (min-width: 768px) {
  header#site-header .mode-toggler .mode-icon {
    margin-right: 8px;
  }
}
header#site-header .mode-toggler .mode-icon.--day {
  background-image: url("../images/icons/icon-time-morning.svg");
}
header#site-header .mode-toggler .mode-icon.--night {
  background-image: url("../images/icons/icon-time-night.svg");
}

#case-page #site-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

#about-page #site-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.navigation {
  display: flex;
}
@media screen and (max-width: 575px) {
  .navigation {
    flex-direction: column;
  }
  .navigation.open {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(12px);
    position: fixed;
    top: 0;
    left: 0;
  }
  .navigation.open .menu {
    padding-top: 120px;
  }
  .navigation.open .menu .menu-list {
    display: flex;
    flex-direction: column;
  }
  .navigation.open .menu .menu-list li.menu-item {
    margin-left: 0;
    font-size: 2.25rem;
  }
}
@media screen and (min-width: 768px) {
  .navigation {
    align-items: center;
    justify-content: flex-end;
    gap: 32px;
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .navigation .menu .menu-list {
    display: none;
  }
}

.menu-toggler {
  height: 40px;
  width: 40px;
  display: none;
  position: relative;
  padding: 15px 10px;
  border: 1px solid var(--color-text--16);
  border-radius: 360px;
  background: rgba(255, 255, 255, 0.16);
  cursor: pointer;
}
@media screen and (max-width: 575px) {
  .menu-toggler {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.menu-toggler span {
  height: 1px;
  width: 100%;
  background-color: white;
  transform-origin: center;
  transition: all 0.3s ease;
}
.menu-toggler.open span:first-child {
  transform: translateY(3px) rotate(45deg);
}
.menu-toggler.open span:last-child {
  transform: translateY(-4px) rotate(-45deg);
}

footer#site-footer {
  position: relative;
  overflow: hidden;
  text-align: center;
  --footer-before-opacity: 1;
}
@media screen and (min-width: 0) {
  footer#site-footer {
    padding: 96px 0;
  }
}
@media screen and (min-width: 768px) {
  footer#site-footer {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 1400px) {
  footer#site-footer {
    min-height: 100vh;
    padding-top: 280px;
    padding-bottom: 160px;
  }
}
footer#site-footer::after {
  content: "";
  width: 200vw;
  height: 100vw;
  border-radius: 100vw 100vw 0 0;
  background-color: transparent;
  opacity: var(--footer-before-opacity);
  position: absolute;
  bottom: -20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
@media screen and (min-width: 768px) {
  footer#site-footer::after {
    width: 100vw;
    height: 50vw;
  }
}
footer#site-footer .row:not(:last-child) {
  margin-bottom: 32px;
}
footer#site-footer .footer--top {
  position: relative;
  min-height: 280px;
}
@media screen and (min-width: 768px) {
  footer#site-footer .footer--top {
    min-height: 496px;
  }
}
footer#site-footer .footer--top::before {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  background-image: url("../images/logotype.svg");
  background-repeat: no-repeat;
  background-size: auto 104px;
  background-position: center;
  filter: var(--color-invert);
}
@media screen and (min-width: 768px) {
  footer#site-footer .footer--top::before {
    background-size: auto 298px;
  }
}
footer#site-footer .footer--top .row:nth-child(1) figure:nth-child(2) {
  display: none;
}
@media screen and (min-width: 768px) {
  footer#site-footer .footer--top .row:nth-child(1) figure:nth-child(2) {
    display: block;
    transform: translateY(168px);
  }
}
footer#site-footer .footer--top .row:nth-child(2) figure:nth-child(1) {
  transform: translateY(12px);
}
@media screen and (min-width: 768px) {
  footer#site-footer .footer--top .row:nth-child(2) figure:nth-child(1) {
    transform: translateY(64px);
  }
}
footer#site-footer .footer--top .row:nth-child(2) figure:nth-child(2) {
  transform: translateY(-12px);
}
@media screen and (min-width: 768px) {
  footer#site-footer .footer--top .row:nth-child(2) figure:nth-child(2) {
    transform: translateY(104px);
  }
}
footer#site-footer .footer--top .row figure img {
  border-radius: 8px;
}
footer#site-footer .footer--bottom h6 {
  margin-bottom: 16px;
}
footer#site-footer .footer--bottom .copyright {
  color: #999;
  font-weight: bold;
  text-transform: uppercase;
}
footer#site-footer .timebased-message, footer#site-footer .timebased-icon {
  display: inline-block;
  vertical-align: middle;
}
footer#site-footer--controls {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (min-width: 0) {
  footer#site-footer--controls {
    position: relative;
    text-align: center;
  }
  footer#site-footer--controls .view-toggle {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  footer#site-footer--controls {
    width: 100%;
    position: fixed;
    bottom: 0;
    text-align: left;
  }
}

.button {
  margin-top: 32px;
  padding: 8px 24px;
  border-radius: 360px;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
}
.button .icon-arrow {
  height: 16px;
  width: 16px;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  right: 24px;
  transition: 250ms ease-in-out;
  vertical-align: middle;
  opacity: 0;
  transform: translateX(-8px);
}
.button .icon-arrow::before, .button .icon-arrow::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/icons/icon-arrow-diagonal.svg");
  background-repeat: no-repeat;
  background-size: cover;
  filter: var(--color-invert);
}
.button .icon-arrow::after {
  transform: translate(-100%, 100%);
}
.button.primary {
  background: #ED603A;
  border: 1px solid rgba(255, 255, 255, 0.5);
  transition: 250ms ease-in-out;
}
.button.secondary {
  background: rgba(255, 255, 255, 0.16);
  border: 1px solid var(--color-text--16);
  backdrop-filter: blur(8px);
  transition: 250ms ease-in-out;
}
.button:hover {
  padding-right: 48px;
}
.button:hover .icon-arrow {
  opacity: 1;
  transform: translateX(0);
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.1s linear;
}
a:link, a:visited {
  color: inherit;
}
a:hover, a:active {
  color: inherit;
}
a.tertiary:link, a.tertiary:visited {
  color: #999;
}
a.tertiary:hover, a.tertiary:active {
  color: white;
}
a.link--mail {
  position: relative;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-text);
}
a.link--mail::before {
  height: 1px;
  width: 100%;
  background: white;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  will-change: transform, color;
  transform: scaleX(0);
  transform-origin: 100% 50%;
  transition: transform 0.35s;
  transition: transform 0.35s, transform 0.35s;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
a.link--mail:hover .arrow::before, a.link--mail:hover .arrow::after {
  animation-duration: 0.35s;
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-delay: 2ms;
}
a.link--mail:hover .arrow::before {
  animation-name: animArrowDown;
}
a.link--mail:hover .arrow::after {
  animation-name: animArrowUp;
}
a.link--mail:hover::before {
  transform: scaleX(1);
  transform-origin: 0 50%;
  transition-timing-function: ease;
}
a.link--mail .arrow {
  height: 20px;
  width: 20px;
  overflow: hidden;
  position: relative;
  transition: 250ms ease-in-out;
  display: inline-block;
  vertical-align: middle;
}
a.link--mail .arrow::before, a.link--mail .arrow::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/icons/icon-arrow-diagonal.svg");
  background-repeat: no-repeat;
  background-size: cover;
  filter: var(--color-invert);
}
a.link--mail .arrow::after {
  transform: translate(-100%, 100%);
}
a.link--inline::after {
  content: "";
  height: 16px;
  width: 16px;
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
  background-image: url("../images/icons/icon-arrow-diagonal.svg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: 250ms ease-in-out;
  filter: var(--color-invert);
}

.list--horizontal li.social:not(:only-of-type):not(:last-of-type), .list--vertical li.social:not(:only-of-type):not(:last-of-type) {
  margin-right: 16px;
}
.list--horizontal li.social:last-child, .list--vertical li.social:last-child {
  margin-right: 0;
}
.list--horizontal li.social a, .list--vertical li.social a {
  display: block;
  padding: 12px;
  border-radius: 100%;
  border: 1.5px solid var(--color-text--50);
  position: relative;
  transition: transform 0.3s ease;
  will-change: transform;
}
.list--horizontal li.social a::after, .list--vertical li.social a::after {
  content: "";
  position: absolute;
  transition: 0.5s;
  height: 0;
  width: 0;
  border-radius: 50%;
  background: var(--color-text);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.list--horizontal li.social a:hover, .list--vertical li.social a:hover {
  border-color: transparent;
}
.list--horizontal li.social a:hover img, .list--vertical li.social a:hover img {
  filter: invert(1);
}
.list--horizontal li.social a:hover::after, .list--vertical li.social a:hover::after {
  width: 100%;
  height: 100%;
}
.list--horizontal li.social a img, .list--vertical li.social a img {
  height: 24px;
  width: 24px;
  filter: var(--color-invert);
  cursor: pointer;
}
.list--horizontal li.unordered, .list--vertical li.unordered {
  font-size: 32px;
  line-height: 48px;
  text-transform: uppercase;
}
@media screen and (min-width: 576px) {
  .list--horizontal li.unordered, .list--vertical li.unordered {
    font-size: 16px;
    line-height: 24px;
  }
}
.list--horizontal li.unordered a::after, .list--vertical li.unordered a::after {
  height: 32px;
  width: 32px;
  margin-left: 8px;
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-image: url("../images/icons/icon-arrow-diagonal.svg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: 250ms ease-in-out;
}
@media screen and (min-width: 576px) {
  .list--horizontal li.unordered a::after, .list--vertical li.unordered a::after {
    display: none;
  }
}
.list--horizontal li.unordered a:hover::after, .list--vertical li.unordered a:hover::after {
  transform: translate(5px, -5px);
}
.list--horizontal li.menu-item, .list--vertical li.menu-item {
  text-transform: uppercase;
  color: var(--color-text--50);
  margin-left: 12px;
}
.list--horizontal li.menu-item:hover, .list--vertical li.menu-item:hover {
  color: var(--color-text);
}
.list--horizontal li.menu-item.active, .list--vertical li.menu-item.active {
  color: var(--color-text);
  font-weight: bold;
}
.list--horizontal li.pagination, .list--vertical li.pagination {
  height: 12px;
  width: 8px;
  background: #484847;
  border-radius: 2px;
  text-indent: -9999px;
}
.list--horizontal li.pagination:not(:only-of-type):not(:last-of-type), .list--vertical li.pagination:not(:only-of-type):not(:last-of-type) {
  margin-right: 24px;
}
.list--horizontal li.pagination.active, .list--vertical li.pagination.active {
  background: white;
}
.list--horizontal li {
  display: inline-block;
}
.list--horizontal li:not(:only-of-type):not(:last-of-type) {
  margin-right: 8px;
}
.list--vertical li {
  display: block;
}
[data-theme=light] .list--horizontal li.social a:hover img, [data-theme=light] .list--vertical li.social a:hover img {
  filter: invert(0);
}

[data-theme=dark] .list--horizontal li.social a:hover img, [data-theme=dark] .list--vertical li.social a:hover img {
  filter: invert(1);
}

.hero {
  height: auto;
  padding-top: 48px;
  position: relative;
  padding-bottom: 80px;
}
@media screen and (min-width: 768px) {
  .hero {
    padding-bottom: 0;
  }
}
.hero .hero-image {
  width: 100%;
  height: 100%;
  position: relative;
}
.hero .hero-image .cb-mask {
  width: 100%;
  padding-top: 62.22%;
  position: relative;
  clip-path: url(#slice1);
  overflow: hidden;
}
.hero .hero-image .cb-mask .image {
  height: auto;
  width: 100%;
}
@media screen and (min-width: 0) {
  .hero .hero-image .cb-mask .image {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (min-width: 768px) {
  .hero .hero-image .cb-mask .image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.hero .hero-image .cb-mask img,
.hero .hero-image .cb-mask video {
  height: calc(100% + 120px);
  min-height: 100vh;
  width: 100%;
  display: block;
  object-fit: cover;
}
.hero .hero-image .hero-image--content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero .hero-image .hero-image--content h2 {
  text-transform: uppercase;
  font-family: "termina", sans-serif;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  margin-top: 10%;
}
.hero .hero-image .hero-image--content h2 span {
  font-family: "termina", sans-serif;
  font-weight: 500;
  font-style: normal;
}
@media screen and (min-width: 768px) {
  .hero .hero-image .hero-image--content h2 {
    font-size: 20px;
  }
}
.hero .hero-hint {
  margin-top: 80px;
  text-align: center;
  z-index: 5;
}
.hero .hero-hint .filmcode {
  margin-bottom: 16px;
}

.bg-signature {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.75;
  z-index: -999;
}
.bg-signature svg {
  height: 100%;
  width: 100%;
  opacity: 0.24;
}
@media screen and (min-width: 576px) {
  .bg-signature svg {
    opacity: 0.48;
  }
}
.bg-signature img {
  height: inherit;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .gallery .row:nth-child(1) .gallery-card:nth-child(2) {
    margin-top: 80px;
  }
  .gallery .row:nth-child(2) .gallery-card:nth-child(2) {
    margin-top: 120px;
  }
}

.reel .videoplayer {
  position: relative;
}
.reel .videoplayer .videoplayer-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}
.reel .videoplayer .videoplayer-wrapper.is-playing .videoplayer-overlay {
  opacity: 0;
  visibility: hidden;
}
.reel .videoplayer .videoplayer-wrapper .videoplayer-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  z-index: 1;
  display: flex;
  align-items: center;
}
.reel .videoplayer .videoplayer-wrapper video {
  cursor: pointer;
}
@media screen and (min-width: 576px) {
  .reel .videoplayer .videoplayer-wrapper video {
    border-radius: 0;
  }
}

.visuals {
  display: none;
}
.visuals figure:first-child {
  margin-top: 120px;
}
@media screen and (min-width: 576px) {
  .visuals figure:first-child {
    margin-to: 0;
  }
}
@media screen and (min-width: 576px) {
  .visuals .js-carousel {
    margin-bottom: 40px;
  }
}

.featured .row {
  margin: 0;
}

.photography {
  overflow: hidden;
}
.photography .menu {
  pointer-events: none;
}
.photography .menu .list--vertical .list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-transform: uppercase;
}
.photography .menu .list--vertical .list-item a {
  display: flex;
  position: relative;
}
.photography .menu .list--vertical .list-item a .card-media {
  width: 160px;
  height: 220px;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
@media screen and (min-width: 576px) {
  .photography .menu .list--vertical .list-item a .card-media {
    width: 320px;
    height: 440px;
  }
}
.photography .menu .list--vertical .list-item a .card-media .card-image--inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 125%;
  overflow: hidden;
  border-radius: 8px;
}
.photography .menu .list--vertical .list-item a .card-media .card-image--inner img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.photography .menu .list--vertical .list-item a .list-item-text {
  position: relative;
  font-family: "Anton";
  font-size: 64px;
  line-height: 112%;
  opacity: 0.5;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-text);
  color: transparent;
  transition: opacity 350ms ease;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (min-width: 576px) {
  .photography .menu .list--vertical .list-item a .list-item-text {
    font-size: 160px;
  }
}
.photography .menu .list--vertical .list-item a .list-item-text .list-item-innertext {
  display: inline-block;
  cursor: pointer;
}
.photography .menu .list--vertical .list-item a .list-item-text:hover, .photography .menu .list--vertical .list-item a .list-item-text.active {
  opacity: 1;
  color: var(--color-text);
  filter: var(--color-invert);
  -webkit-text-stroke-color: transparent;
}
.photography .menu:hover .list-item-text {
  opacity: 0.25;
}
.photography .list-item:hover .list-item-text {
  z-index: 10;
  mix-blend-mode: difference;
}
.photography .list-item:hover .card-media {
  z-index: 2;
}

.about-header {
  overflow: hidden;
  padding-top: 280px;
}
.about-header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  z-index: -1;
  will-change: transform;
  transition: transform 0.2s;
}
@media screen and (min-width: 0) {
  .about-header .row:first-child {
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 768px) {
  .about-header .row:first-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 575px) {
  .about-header .breadcrumb {
    margin-left: 12px;
  }
}
@media screen and (max-width: 575px) {
  .about-header .indicator {
    margin-left: 12px;
  }
}
.about-header .hero-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .about-header .hero-content {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(4, 1fr);
  }
}
.about-header .hero-content h1 {
  display: contents;
  color: #ED603A;
}
.about-header .hero-content h1 .headline-word,
.about-header .hero-content h1 .char {
  display: inline-flex;
}
@media screen and (min-width: 0) {
  .about-header .hero-content h1 .headline-word:nth-child(1) {
    grid-column: 1/span 2;
    grid-row: 1;
  }
}
@media screen and (min-width: 576px) {
  .about-header .hero-content h1 .headline-word:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }
}
@media screen and (min-width: 0) {
  .about-header .hero-content h1 .headline-word:nth-child(2) {
    grid-column: 1/span 3;
    grid-row: 2;
    text-align: right;
  }
}
@media screen and (min-width: 768px) {
  .about-header .hero-content h1 .headline-word:nth-child(2) {
    grid-column: 4/span 1;
    grid-row: 1;
  }
}
@media screen and (min-width: 0) {
  .about-header .hero-content h1 .headline-word:nth-child(3) {
    grid-column: 1/span 2;
    grid-row: 3;
  }
}
@media screen and (min-width: 768px) {
  .about-header .hero-content h1 .headline-word:nth-child(3) {
    grid-column: 3/span 2;
    grid-row: 2;
  }
}
@media screen and (min-width: 0) {
  .about-header .hero-content h1 .headline-word:nth-child(4) {
    grid-column: 1/span 2;
    grid-row: 4;
  }
}
@media screen and (min-width: 768px) {
  .about-header .hero-content h1 .headline-word:nth-child(4) {
    grid-column: 2/span 2;
    grid-row: 3;
    text-align: right;
  }
}
.about-header .parallax-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 120px);
  min-height: 100vh;
  background-image: url("../images/cb-about.jpg");
  background-size: cover;
  background-position: center;
  transform: translateZ(0);
  z-index: -1;
  will-change: transform;
  transition: transform 0.2s;
}

.about .content:not(:last-child) {
  margin-bottom: 64px;
}
@media screen and (min-width: 768px) {
  .about .content {
    margin-bottom: 0;
  }
  .about .content:nth-child(2) {
    padding-top: 160px;
  }
}
@media screen and (min-width: 0) {
  .about .gallery-card {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 768px) {
  .about .gallery-card {
    margin-top: 160px;
  }
}

.mission {
  overflow: hidden;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .mission {
    min-height: 800px;
  }
}
.mission .images {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9999;
}
@media screen and (max-width: 575px) {
  .mission .images {
    opacity: 0.64;
  }
}
.mission .images .gallery-card {
  position: absolute;
  max-width: 152px;
  animation: animFocusBlurOut 2.5s ease-in-out;
}
@media screen and (min-width: 768px) {
  .mission .images .gallery-card {
    max-width: 304px;
  }
}
.mission .images .gallery-card:nth-child(1) {
  top: 32%;
  left: 50%;
  transform: translate(-150%, -50%) rotate(-6deg);
  filter: blur(2px);
}
@media screen and (min-width: 768px) {
  .mission .images .gallery-card:nth-child(1) {
    top: 48%;
    transform: translate(-200%, -50%) rotate(-6deg);
  }
}
.mission .images .gallery-card:nth-child(2) {
  top: 64%;
  left: 50%;
  transform: translate(50%, -50%) rotate(6deg);
}
@media screen and (min-width: 768px) {
  .mission .images .gallery-card:nth-child(2) {
    top: 72%;
    transform: translate(100%, -50%) rotate(6deg);
  }
}

@media screen and (min-width: 768px) {
  .services .row {
    align-items: center;
  }
  .services .row:not(:last-child) {
    margin-bottom: 160px;
  }
  .services .row:nth-child(3) {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 0) {
  .services .row .image {
    margin-bottom: 48px;
  }
}
@media screen and (min-width: 768px) {
  .services .row .image {
    margin-bottom: 0;
  }
}
.services .row .image .gallery-card--media {
  max-width: 320px;
  margin: 0 auto;
}
.behindthescenes {
  overflow: hidden;
}
@media screen and (min-width: 0) {
  .behindthescenes .gallery-card {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 0) {
  .capabilities .content,
  .brandvalues .content {
    margin-bottom: 64px;
  }
}
@media screen and (min-width: 768px) {
  .capabilities .content,
  .brandvalues .content {
    margin-bottom: 0;
  }
  .capabilities .content:nth-child(1),
  .brandvalues .content:nth-child(1) {
    padding-top: 64px;
  }
  .capabilities .content:nth-child(2),
  .brandvalues .content:nth-child(2) {
    padding-top: 160px;
  }
}
.capabilities .content .gallery-card--media,
.brandvalues .content .gallery-card--media {
  display: inline-block;
  margin-bottom: 16px;
}
@media screen and (min-width: 0) {
  .capabilities .content .gallery-card--media,
  .brandvalues .content .gallery-card--media {
    width: auto;
  }
}
@media screen and (min-width: 768px) {
  .capabilities .content .gallery-card--media,
  .brandvalues .content .gallery-card--media {
    max-width: 152px;
  }
}

.recognitions {
  display: none;
}

.inspire,
.cta-trigger {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-top: 160px;
}
@media screen and (min-width: 576px) {
  .inspire,
  .cta-trigger {
    min-height: 800px;
  }
}
@media screen and (min-width: 1400px) {
  .inspire,
  .cta-trigger {
    min-height: 100vh;
  }
}

.inspire {
  display: none;
}

#about-page .cta-trigger .parallax-image {
  background-image: url("../images/cb-trigger-1.jpg");
}
#about-page .cta-trigger .parallax-image::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.32);
}

.stories {
  padding: 0;
}
.stories .parallax-container {
  height: 100vh;
  overflow: hidden;
}
@media screen and (min-width: 576px) {
  .stories .parallax-container {
    max-height: 100vh;
  }
}
.stories .parallax-container canvas.image-sequence {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.stories .parallax-container .content {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
  z-index: 1;
  padding: 120px 64px;
  background-color: rgba(0, 0, 0, 0.5);
}
.stories .parallax-container .content .logo-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
.stories .parallax-container .content .logo-wrapper .logo {
  margin: 0 auto;
  background-image: url("../images/logotype.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (min-width: 0) {
  .stories .parallax-container .content .logo-wrapper .logo {
    height: 72px;
    width: 180px;
  }
}
@media screen and (min-width: 768px) {
  .stories .parallax-container .content .logo-wrapper .logo {
    height: 100px;
    width: 248px;
  }
}
.stories .parallax-container .content p {
  color: rgba(255, 255, 255, 0.8);
}

.banner {
  display: none;
  overflow: hidden;
}
.banner .banner-content {
  width: 100%;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1;
}
.banner .banner-content p,
.banner .banner-content img {
  display: inline-block;
  vertical-align: middle;
}
.banner .banner-content p {
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 0 !important;
}
.banner .banner-content img {
  max-height: 40px;
  max-width: inherit;
  border-radius: 4px;
}
@media screen and (min-width: 576px) {
  .banner .banner-content img {
    max-height: 96px;
    border-radius: 12px;
  }
}
.banner .banner-content .scroller-item--top {
  transform: translateX(-12rem);
}
@media screen and (min-width: 576px) {
  .banner .banner-content .scroller-item--top {
    transform: translateX(-4rem);
  }
}
@media screen and (min-width: 576px) {
  .banner .banner-content .scroller-item--top p {
    margin-right: 40px;
  }
}
.banner .banner-content .scroller-item--middle {
  transform: translateX(0rem);
  text-align: center;
}
@media screen and (min-width: 576px) {
  .banner .banner-content .scroller-item--middle img {
    margin-left: 12px;
  }
}
.banner .banner-content .scroller-item--bottom {
  transform: translateX(12rem);
}
@media screen and (min-width: 576px) {
  .banner .banner-content .scroller-item--bottom {
    transform: translateX(2rem);
  }
}
@media screen and (min-width: 576px) {
  .banner .banner-content .scroller-item--bottom img {
    margin-right: 12px;
  }
}

.case-hero {
  padding: 0;
}
@media screen and (min-width: 576px) {
  .case-hero {
    height: 80vh;
  }
}
.case-hero .case-wrapper {
  height: 100%;
  width: 100%;
}
.case-hero .case-wrapper .case-image {
  height: 100%;
  width: 100%;
}
.case-hero .case-wrapper .case-image img {
  height: calc(100% + 96px);
  width: 100%;
  object-fit: cover;
}
.case-hero .case-wrapper .case-content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-hero .case-wrapper .case-content h1 {
  font-size: 160px;
  line-height: 120%;
  color: #9CABFF;
}
@media screen and (min-width: 576px) {
  .case-hero .case-wrapper .case-content h1 {
    font-size: 64px;
  }
}
.case-hero .case-wrapper .case-content h1 span {
  text-decoration: underline;
}

.case-detail .case-intro .case-overview--tags {
  margin-top: 8px;
}
@media screen and (min-width: 576px) {
  .case-detail .case-content {
    margin-bottom: 40px;
  }
}
.case-detail .case-content .row {
  margin-bottom: 80px;
}
@media screen and (min-width: 576px) {
  .case-detail .case-content .row {
    margin-bottom: 0;
  }
}
.case-detail .case-content img {
  width: 100%;
}
.case-detail .case-content figcaption {
  margin-top: 12px;
}
.case-detail .case-content h3 {
  margin-bottom: 8px;
}
.case-detail .mobile-tree .row {
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 576px) {
  .case-detail .mobile-tree .row {
    flex-direction: column;
  }
}
.case-detail .mobile-tree .row div:last-child {
  margin-top: 40px;
}
.case-detail .mobile-tree .row.reverse div:nth-child(1) {
  order: 2;
}
@media screen and (min-width: 576px) {
  .case-detail .mobile-tree .row.reverse div:nth-child(1) {
    order: 1;
  }
}
.case-detail .mobile-tree .row.reverse div:nth-child(2) {
  order: 1;
}
@media screen and (min-width: 576px) {
  .case-detail .mobile-tree .row.reverse div:nth-child(2) {
    order: 2;
  }
}
.case-detail .mobile-tree .row ul.mobile-screens li {
  width: calc(50% - 12px);
  margin-bottom: 24px;
  /* 50% breedte met 12px als ruimte tussen kolommen */
}
.case-detail .mobile-tree .row ul.mobile-screens li:nth-child(2) {
  margin-top: 24%;
}
@media screen and (min-width: 576px) {
  .case-detail .mobile-tree .row ul.mobile-screens li:nth-child(2) {
    margin-top: 16%;
  }
}
.case-detail .mobile-tree .row ul.mobile-screens li:nth-child(2n) {
  float: right;
  clear: right;
}
.case-detail .mobile-tree .row ul.mobile-screens li:nth-child(odd) {
  clear: left;
  float: left;
}
.case-detail .mobile-tree .row ul.mobile-screens li:last-child {
  margin-bottom: 0;
}
.case-detail .case-media .gallery-card {
  margin-bottom: 24px;
}
.case-detail .case-media .gallery-card:last-child {
  margin-bottom: 0;
}
.case-detail .case-media .gallery-card img {
  margin-left: auto;
  margin-right: auto;
}

#rituals .case-intro {
  background-color: #0E182F;
  color: white;
}

#euro2020 .case-intro {
  background-color: #4BBDCF;
  color: white;
}

#klm .case-intro {
  background-color: #00A1DE;
  color: white;
}

html::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

#photography {
  position: relative;
}
#photography .wrapper {
  padding: 0;
  justify-content: center;
}
#photography .wrapper .slider-vertical {
  height: 100%;
}
#photography .wrapper .slider-vertical .slide {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
#photography .wrapper .slider-vertical .slide .row {
  padding: 24px 0 0;
}
@media screen and (min-width: 576px) {
  #photography .wrapper .slider-vertical .slide .row {
    padding: 0;
  }
}
#photography .wrapper .slider-vertical .slide .films {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
#photography .wrapper .slider-vertical .slide .films .filmframes {
  display: flex;
  gap: 4px;
  border: 2px solid var(--color-text--16);
  border-right: none;
  position: relative;
  padding: 12px;
  border-radius: 16px 0 0 16px;
  background-color: var(--color-background);
}
@media screen and (min-width: 576px) {
  #photography .wrapper .slider-vertical .slide .films .filmframes {
    padding: 8px;
    border-radius: 12px 0 0 12px;
  }
}
#photography .wrapper .slider-vertical .slide .films .filmframes li.frame {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
#photography .wrapper .slider-vertical .slide .films .filmframes li.frame::after {
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../images/kodak.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 575px) {
  #photography .wrapper .slider-vertical .slide .films .filmframes li.frame:nth-last-child(-n+2) {
    display: none;
  }
}
#photography .wrapper .slider-vertical .slide .films .filmframes li.frame img {
  width: 100%;
  display: block;
  /*   transform: scale(0.9); */
}
#photography .wrapper .slider-vertical .slide .filmleader,
#photography .wrapper .slider-vertical .slide .filmframes {
  display: flex;
  position: relative;
}
#photography .wrapper .slider-vertical .slide .filmleader .filmleader--left {
  height: 100%;
  width: 240px;
  justify-content: flex-end;
}
@media screen and (min-width: 576px) {
  #photography .wrapper .slider-vertical .slide .filmleader .filmleader--left {
    min-width: 100%;
    width: 120px;
  }
}
#photography .wrapper .slider-vertical .slide .filmleader .filmleader--left .top {
  height: 40px;
  width: 100%;
  position: relative;
}
@media screen and (min-width: 576px) {
  #photography .wrapper .slider-vertical .slide .filmleader .filmleader--left .top {
    height: 24px;
  }
}
#photography .wrapper .slider-vertical .slide .filmleader .filmleader--left .top:before, #photography .wrapper .slider-vertical .slide .filmleader .filmleader--left .top:after {
  content: "";
  position: absolute;
  z-index: 1;
}
#photography .wrapper .slider-vertical .slide .filmleader .filmleader--left .top:before {
  top: 0;
  left: 0px;
  height: 42px;
  width: 80px;
  background-image: url("../images/film.svg");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  filter: var(--color-invert);
}
#photography .wrapper .slider-vertical .slide .filmleader .filmleader--left .bottom {
  border-radius: 0 16px 16px 0;
  border-width: 2px;
  border-left: none;
  background-color: var(--color-background);
}
@media screen and (min-width: 576px) {
  #photography .wrapper .slider-vertical .slide .filmleader .filmleader--left .bottom {
    border-radius: 0 12px 12px 0;
  }
}
#photography .wrapper .slider-vertical .slide .filmleader .filmleader--left .bottom:after {
  content: "";
  background-color: var(--color-background);
  position: absolute;
  top: -2px;
  left: 0;
  height: 2px;
  width: 80px;
}
#photography .wrapper .slider-vertical .slide .filmleader .arrow::after {
  height: 48px;
  width: 48px;
  opacity: 64%;
  filter: var(--color-invert);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  display: inline-block;
  vertical-align: middle;
  background-image: url("../images/icons/icon-arrow-diagonal.svg");
  background-repeat: no-repeat;
  background-size: cover;
  transition: 250ms ease-in-out;
}
@media screen and (min-width: 576px) {
  #photography .wrapper .slider-vertical .slide .filmleader .arrow::after {
    height: 24px;
    width: 24px;
  }
}
#photography .bg-signature {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

main.album {
  position: relative;
}
@media screen and (min-width: 768px) {
  main.album {
    height: 100vh;
    padding-top: 112px;
    padding-bottom: 112px;
  }
}
main.album .slider {
  padding: 0;
}
@media screen and (min-width: 768px) {
  main.album .slider {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    width: max-content;
  }
}
@media screen and (min-width: 0) {
  main.album .slider .slider-wrapper {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper {
    height: 64vh;
    display: flex;
    width: max-content;
    padding-left: 12px;
    padding-right: 24px;
  }
}
main.album .slider .slider-wrapper .filmleader,
main.album .slider .slider-wrapper .filmframes {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper .filmleader,
  main.album .slider .slider-wrapper .filmframes {
    height: 64vh;
    min-height: 600px;
    flex-direction: row;
  }
}
@media screen and (min-width: 0) {
  main.album .slider .slider-wrapper .filmleader {
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper .filmleader {
    margin-bottom: 0;
    margin-right: 8px;
  }
}
main.album .slider .slider-wrapper .filmleader .filmleader--left {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: row;
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper .filmleader .filmleader--left {
    height: 100%;
    min-width: 752px;
    margin-right: 8px;
    flex-direction: column;
  }
}
main.album .slider .slider-wrapper .filmleader .filmleader--left .top,
main.album .slider .slider-wrapper .filmleader .filmleader--left .bottom {
  display: flex;
}
@media screen and (min-width: 0) {
  main.album .slider .slider-wrapper .filmleader .filmleader--left .top {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper .filmleader .filmleader--left .top {
    display: flex;
    height: 141px;
    width: 100%;
    position: relative;
  }
}
main.album .slider .slider-wrapper .filmleader .filmleader--left .top:before, main.album .slider .slider-wrapper .filmleader .filmleader--left .top:after {
  content: "";
  position: absolute;
  z-index: 1;
}
main.album .slider .slider-wrapper .filmleader .filmleader--left .top:before {
  height: 145px;
  width: 280px;
  top: 0;
  right: 0px;
  background-image: url("../images/filmstrip-top.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  opacity: 0.2;
  z-index: 1;
  filter: var(--color-invert);
}
main.album .slider .slider-wrapper .filmleader .filmleader--left .top:after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  height: 4px;
  width: 100%;
}
main.album .slider .slider-wrapper .filmleader .filmleader--left .bottom {
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 0) {
  main.album .slider .slider-wrapper .filmleader .filmleader--left .bottom {
    padding: 96px 0;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper .filmleader .filmleader--left .bottom {
    text-align: left;
    padding: 72px;
    border-radius: 16px 0 16px 16px;
    border-width: 4px;
    border-radius: 16px 0 16px 16px;
    border-style: solid;
    border-color: var(--color-text--16);
  }
}
main.album .slider .slider-wrapper .filmleader .filmleader--left .bottom:after {
  content: "";
  background-color: var(--color-background);
  position: absolute;
  top: -4px;
  right: 0;
  height: 4px;
  width: 276px;
}
main.album .slider .slider-wrapper .filmleader .filmleader--left .bottom h1 {
  margin-bottom: 24px;
}
main.album .slider .slider-wrapper .filmleader .filmleader--left .bottom p {
  color: var(--color-text--50);
}
main.album .slider .slider-wrapper .filmleader .filmleader--right {
  background: black;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper .filmleader .filmleader--right {
    height: 100%;
  }
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper .filmleader .filmleader--right img {
    height: 100%;
    /* Set the height of the element to 100% of the parent div */
    width: auto;
    max-width: initial;
  }
}
@media screen and (min-width: 0) {
  main.album .slider .slider-wrapper .filmframes {
    gap: 24px;
  }
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper .filmframes {
    gap: 8px;
  }
}
main.album .slider .slider-wrapper .filmframes li.frame {
  overflow: hidden;
  border-radius: 8px;
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper .filmframes li.frame {
    height: 100%;
    background: black;
    position: relative;
  }
}
main.album .slider .slider-wrapper .filmframes li.frame.half {
  height: 50%;
  margin-left: 17.5rem;
}
@media screen and (min-width: 768px) {
  main.album .slider .slider-wrapper .filmframes li.frame img {
    height: 100%;
    width: auto;
    max-width: initial;
  }
}
main.album .slider.grid .filmframes {
  flex-wrap: wrap;
  height: auto;
  gap: 0;
}
main.album .slider.grid .filmframes .frame {
  height: auto !important;
  width: 25%;
}
main.album .slider.grid .filmframes .frame img {
  height: auto !important;
  width: 100% !important;
}

@media screen and (min-width: 768px) {
  .view-toggle li {
    cursor: pointer;
  }
  .view-toggle li.view-slider {
    height: 24px;
    width: 40px;
    background-image: url("../images/icon-filmrol.svg");
    background-repeat: no-repeat;
    opacity: 0.5;
  }
  .view-toggle li.view-grid {
    height: 24px;
    width: 20px;
    background-image: url("../images/icon-single.svg");
    background-repeat: no-repeat;
    opacity: 0.5;
  }
  .view-toggle li.active {
    opacity: 1;
  }
}

.filter {
  display: none;
}