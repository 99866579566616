// ----------------------------------------------------------
// Contains all the list styles.
// ----------------------------------------------------------
@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;

.list {

    &--horizontal,
    &--vertical {
        li {
            &.social {
                &:not(:only-of-type):not(:last-of-type) {
                    margin-right: 16px;
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    display: block;
                    padding: 12px;

                    border-radius: 100%;
                    border: 1.5px solid var(--color-text--50);

                    position: relative;
                    transition: transform 0.3s ease;
                    will-change: transform;

                    &::after {
                        content: "";
                        position: absolute;
                        transition: 0.5s;
                        height: 0;
                        width: 0;
                        border-radius: 50%;
                        background: var(--color-text); // $color-white
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: -1;
                    }

                    &:hover {
                        border-color: transparent;

                        img {
                            filter: invert(1);
                        }

                        &::after {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    img {
                        height: 24px;
                        width: 24px;
                        filter: var(--color-invert);
                        cursor: pointer; // Fix for hover state
                    }
                }
            }

            &.unordered {
                font-size: 32px;
                line-height: 48px;
                text-transform: uppercase;

                @include mq(sm) {
                    font-size: 16px;
                    line-height: 24px;
                }

                a::after {
                    height: 32px;
                    width: 32px;
                    margin-left: 8px;

                    content: "";
                    display: inline-block;
                    vertical-align: middle;

                    background-image: url('../images/icons/icon-arrow-diagonal.svg');
                    background-repeat: no-repeat;
                    background-size: cover;
                    transition: 250ms ease-in-out;

                    @include mq(sm) {
                        display: none;
                    }

                }

                a:hover::after {
                    transform: translate(5px, -5px);
                }

            }

            &.menu-item {
                text-transform: uppercase;
                color: var(--color-text--50);
                margin-left: 12px;

                &:hover {
                    color: var(--color-text);
                }

                &.active {
                    color: var(--color-text);
                    font-weight: bold;
                }
            }

            &.pagination {
                height: 12px;
                width: 8px;
                background: #484847;
                border-radius: 2px;
                text-indent: -9999px;

                &:not(:only-of-type):not(:last-of-type) {
                    margin-right: 24px;
                }

                &.active {
                    background: $color-white;
                }
            }
        }
    }

    // horizontal unordered list styles
    &--horizontal {
        li {
            display: inline-block; // Override horizontal list

            &:not(:only-of-type):not(:last-of-type) {
                margin-right: 8px;
            }
        }
    }

    // vertical unordered list styles
    &--vertical {
        li {
            display: block; // Override vertical list

            &:not(:only-of-type):not(:last-of-type) {
                // margin-right: 24px; // Horizontale marge
            }
        }
    }
}


[data-theme="light"] {
    .list {
        &--horizontal,
        &--vertical {
            li.social a:hover {
                img {
                    filter: invert(0);
                }
            }
        }
    }
}

[data-theme="dark"] {
    .list {
        &--horizontal,
        &--vertical {
            li.social a:hover {
                img {
                    filter: invert(1);
                }
            }
        }
    }
}