// about.scss styling

// @use '../00-abstract/variables/_constants.scss' as *;
@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;


// SECTION: .hero 
.about-header {

    // height: 90vh;
    // min-height: 1080px;
    // position: relative;
    overflow: hidden;
    padding-top: 280px;
    // display: flex;
    // align-items: flex-end;

    // @include mq(sm) {
    //     min-height: 100vh;
    // }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
        z-index: -1; // Zorg ervoor dat de afbeelding achter de tekst staat
        will-change: transform; // Optimalisatie voor de parallax effect
        transition: transform 0.2s; // Optionele transitie voor een vloeiende ervaring
    }

    .row:first-child {
        @include mq(xs) {
            margin-bottom: 24px;
        }

        @include mq(md) {
            margin-bottom: 0;
        }
    }

    .breadcrumb {
        @include mq-only(xs) {
            margin-left: 12px;
        }
    }

    .indicator {
        @include mq-only(xs) {
            margin-left: 12px;
        }
    }

    .hero-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        overflow: hidden;

        @include mq(md) {
            grid-template-rows: repeat(3, auto);
            grid-template-columns: repeat(4, 1fr);
        }

        h1 {
            display: contents;
            color: $color-primary;

            .headline-word,
            .char {
                display: inline-flex;
            }

            .headline-word {

                &:nth-child(1) {
                    @include mq(xs) {
                        grid-column: 1 / span 2;
                        grid-row: 1;
                    }

                    @include mq(sm) {
                        grid-column: 1;
                        grid-row: 1;
                    }
                }

                &:nth-child(2) {
                    @include mq(xs) {
                        grid-column: 1 / span 3;
                        grid-row: 2;
                        text-align: right;
                    }

                    @include mq(md) {
                        grid-column: 4 / span 1;
                        grid-row: 1;
                    }
                }

                &:nth-child(3) {
                    @include mq(xs) {
                        grid-column: 1 / span 2;
                        grid-row: 3;
                    }

                    @include mq(md) {
                        grid-column: 3 / span 2;
                        grid-row: 2;
                    }
                }

                &:nth-child(4) {
                    @include mq(xs) {
                        grid-column: 1 / span 2;
                        grid-row: 4;
                    }

                    @include mq(md) {
                        grid-column: 2 / span 2;
                        grid-row: 3;
                        text-align: right;
                    }
                }
            }
        }

    }

    .parallax-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 120px);
        min-height: 100vh;
        background-image: url('../images/cb-about.jpg');
        background-size: cover;
        background-position: center;
        transform: translateZ(0); // Zorgt ervoor dat de parallax effect goed werkt
        z-index: -1; // Zorg ervoor dat de afbeelding achter de tekst staat
        will-change: transform; // Optimalisatie voor de parallax effect
        transition: transform 0.2s; // Optionele transitie voor een vloeiende ervaring
    }

    // Card top
    // .hero-card-media {
    //     margin-top: 120px;
    //     @include center-element-x;

    //     &:last-child {
    //         // transform: translate(-50%, -50%) rotate(8deg);
    //     }

    //     @include mq(sm) {
    //         margin-top: 40px;
    //     }

    //     .hero-image--holder {
    //         width: 100%;
    //         max-width: 560px;
    //         margin: 0 auto;
    //         overflow: hidden;
    //         border-radius: $border-radius--large;

    //         @include mq(sm) {
    //             width: 295px;
    //             border-radius: $border-radius--medium;
    //         }
    //     }

    // }

}


// SECTION: .about 
.about {
    .content {
        &:not(:last-child) {
            margin-bottom: 64px;
        }

        @include mq(md) {
            margin-bottom: 0;

            &:nth-child(2) {
                padding-top: 160px;
            }
        }
    }

    .gallery-card {
        @include mq(xs) {
            margin-bottom: 32px;
        }

        @include mq(md) {
            margin-top: 160px;
        }
    }
}


// SECTION: .mission
.mission {
    overflow: hidden;
    display: flex;
    align-items: center;

    @include mq(md) {
        min-height: 800px;
    }

    .images {
        height: 100%;
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: -9999;

        @include mq-only(xs) {
            opacity: 0.64;
        }

        .gallery-card {
            position: absolute;
            max-width: 152px;
            animation: animFocusBlurOut 2.5s ease-in-out;

            @include mq(md) {
                max-width: 304px;
            }

            &:nth-child(1) {
                top: 32%;
                left: 50%;
                transform: translate(-150%, -50%) rotate(-6deg);
                filter: blur(2px);

                @include mq(md) {
                    top: 48%;
                    transform: translate(-200%, -50%) rotate(-6deg); // Vanaf het midden naar links
                }
            }

            &:nth-child(2) {
                top: 64%;
                left: 50%;
                transform: translate(50%, -50%) rotate(6deg);

                @include mq(md) {
                    top: 72%;
                    transform: translate(100%, -50%) rotate(6deg); // Vanaf het midden naar rechts
                }
            }
        }
    }
}


// SECTION: .services
.services {
    .row {
        // flex-direction: column;
        // overflow: hidden;

        @include mq(md) {
            // flex-direction: row;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 160px;
            }

            &:nth-child(3) {
                flex-direction: row-reverse;
            }
        }

        .image {
            @include mq(xs) {
                margin-bottom: 48px;
            }

            @include mq(md) {
                margin-bottom: 0;
            }

            .gallery-card--media {
                max-width: 320px;
                margin: 0 auto;
            }
        }

        .content {}

    }
}

// section: .behindthescenes
.behindthescenes {
    overflow: hidden;
    // padding: 0;

    .gallery-card {
        @include mq(xs) {
            margin-bottom: 16px;
        }
    }

}

// SECTION: .capabilities .brandvalues
.capabilities,
.brandvalues {
    .content {
        @include mq(xs) {
            margin-bottom: 64px;
        }

        @include mq(md) {
            margin-bottom: 0;

            &:nth-child(1) {
                padding-top: 64px;
            }

            &:nth-child(2) {
                padding-top: 160px;
            }
        }

        .gallery-card--media {
            display: inline-block;
            margin-bottom: 16px;

            @include mq(xs) {
                width: auto; // 96px default
            }

            @include mq(md) {
                max-width: 152px;
            }
        }
    }

}

// SECTION: .recognitions & awards
.recognitions {
    display: none;
}

// SECTION: .inspire
.inspire,
.cta-trigger {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-top: 160px; // Temporary fix

    @include mq(sm) {
        // min-height: auto;
        min-height: 800px;
    }

    @include mq(xxl) {
        // min-height: auto;
        min-height: 100vh;
    }
}

.inspire {
    display: none;
}

#about-page {
    .cta-trigger {
        .parallax-image {
            background-image: url('../images/cb-trigger-1.jpg');

            &::after {
				content: "";
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba($color: #000, $alpha: 0.32);
			}
        }
    }

}

// SECTION: .stories
.stories {
    padding: 0;

    .parallax-container {
        // position: relative; // Doesn't work with display: flex
        height: 100vh; // Fullscreen hoogte van 200vh
        // max-height: 1600px;
        overflow: hidden;

        @include mq(sm) {
            max-height: 100vh;
        }

        canvas.image-sequence {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .content {
            display: flex;
            flex-direction: column;
            position: absolute;
            height: 100%;
            width: 100%;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            color: white; // Zorgt ervoor dat de tekst opvalt tegen de achtergrond
            text-align: center;
            z-index: 1;
            padding: 120px 64px;
            background-color: rgba(0, 0, 0, 0.5); // Optioneel: achtergrondkleur voor betere leesbaarheid

            .logo-wrapper {
                height: 100%;
                display: flex;
                align-items: center; // Centreer horizontaal

                .logo {
                    margin: 0 auto;
                    background-image: url('../images/logotype.svg');
                    background-size: contain;
                    background-repeat: no-repeat;

                    @include mq(xs) {
                        height: 72px;
                        width: 180px;
                    }

                    @include mq(md) {
                        height: 100px;
                        width: 248px;
                    }
                }
            }

            p {
                color: $color-white--80;

            }

        }
    }
}

// SECTION: .scroller



// .banner
.banner {
    display: none;
    overflow: hidden;
    // text-align: center;

    .banner-content {
        width: 100%;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        line-height: 1;

        p,
        img {
            display: inline-block;
            vertical-align: middle;
        }

        p {
            font-size: 40px;
            line-height: 48px;
            text-transform: uppercase;
            font-weight: 600;
            padding-bottom: 0 !important; // fix for now

            @include mq(mq) {
                font-size: 120px;
                line-height: 120px;
            }
        }

        img {
            max-height: 40px;
            max-width: inherit;
            border-radius: $border-radius--xs;

            @include mq(sm) {
                max-height: 96px;
                border-radius: $border-radius--medium;
            }
        }

        .scroller-item--top {
            transform: translateX(-12rem);

            @include mq(sm) {
                transform: translateX(-4rem);
            }

            p {
                @include mq(sm) {
                    margin-right: 40px;
                }
            }

        }

        .scroller-item--middle {
            transform: translateX(0rem);
            text-align: center;

            img {
                @include mq(sm) {
                    margin-left: 12px;
                }
            }
        }

        .scroller-item--bottom {
            transform: translateX(12rem);

            @include mq(sm) {
                transform: translateX(2rem);
            }

            img {
                @include mq(sm) {
                    margin-right: 12px;
                }
            }

        }
    }
}