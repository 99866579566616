// ----------------------------------------------------------
// Contains the helper classes.
// ----------------------------------------------------------

@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;


.overflow { overflow: hidden; }

.p0 { padding: 0; }
.pt0 { padding-top: 0; }
.pr0 { padding-right: 0; }
.pb0 { padding-bottom: 0; }
.pl0 { padding-left: 0; }


// Text align
.txt-left { text-align: left; }
.txt-right { text-align: right; }
.txt-center { text-align: center; }

.txt-justify { text-align: justify; }
.txt-nowrap { white-space: nowrap; }
.txt-lowercase { text-transform: lowercase; }
.txt-uppercase { text-transform: uppercase; }
.txt-capitalize { text-transform: capitalize; }


// Z-index
.zindex-1 { z-index: 1; }
.zindex-2 { z-index: 2; }
.zindex-3 { z-index: 3; }
.zindex-4 { z-index: 4; }


// Positions
.fixed { position: fixed; }
.relative { position: relative; }
.absolute { position: absolute; }


// Floats
.left { float: left; }
.center { 
	margin: 0 auto; 
	display: inline-block;
	float: none;
	text-align: center;
}
.right { float: right; }


// Display
.inline { display: inline; }
.block { display: block; }
.inline-block { display: inline-block; }
.hidden { display: none; }


// MARGINS
// Top margin
.mt-none { margin-top: 0; }
.mt-quarter { margin-top: quarter($base-space-unit); }
.mt-halve { margin-top: halve($base-space-unit); }
.mt-one { margin-top: $base-space-unit; }
.mt-two { margin-top: double($base-space-unit); }
.mt-three { margin-top: threefold($base-space-unit); }
.mt-four { margin-top: quadruple($base-space-unit); }

// Bottom margin
.mb-none { margin-bottom: 0; }
.mb-quarter { margin-bottom: quarter($base-space-unit); }
.mb-halve { margin-bottom: halve($base-space-unit); }
.mb-one { margin-bottom: $base-space-unit; }
.mb-two { margin-bottom: double($base-space-unit); }
.mb-three { margin-bottom: threefold($base-space-unit); }
.mb-four { margin-bottom: quadruple($base-space-unit); }


// Right margin
.mr-none { margin-right: 0; }
.mr-quarter { margin-right: quarter($base-space-unit); }
.mr-halve { margin-right: halve($base-space-unit); }
.mr-one { margin-right: $base-space-unit; }
.mr-three { margin-right: threefold($base-space-unit); }
.mr-four { margin-right: quadruple($base-space-unit); }

// Left margin
.ml-none { margin-left: 0; }
.ml-quarter { margin-left: quarter($base-space-unit); }
.ml-halve { margin-left: halve($base-space-unit); }
.ml-one { margin-left: $base-space-unit; }
.ml-two { margin-left: double($base-space-unit); }
.ml-three { margin-left: threefold($base-space-unit); }
.ml-four { margin-left: quadruple($base-space-unit); }

// All margins
.margin-none { margin: 0; }
.margin-quarter { margin: quarter($base-space-unit); }
.margin-halve { margin: halve($base-space-unit); }
.margin-one { margin: $base-space-unit; }
.margin-two { margin: double($base-space-unit); }
.margin-three { margin: threefold($base-space-unit); }
.margin-four { margin: quadruple($base-space-unit); }


// Border Radius
.radius { 
	border-radius: $border-radius--small; 
	@include mq(sm) {
		border-radius: calc($border-radius--small / 2); 
	}
}
.radius-top { border-radius: $border-radius--small $border-radius--small 0 0; }
.radius-right { border-radius: 0 $border-radius--small $border-radius--small 0; }
.radius-bottom { border-radius: 0 0 $border-radius--small $border-radius--small; }
.radius-left { border-radius: $border-radius--small 0 0 $border-radius--small; }

.radius { 
	overflow: hidden;
}

.anim--handwave {
	animation: animHandWave 2.5s infinite;
	transform-origin: 70% 70%;
	display: inline-block;
}