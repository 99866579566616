// ----------------------------------------------------------
// Contains all the grid layout styles.
// ----------------------------------------------------------

@use '../00-abstract/mixins' as *;
// @use '../00-abstract/variables/_constants.scss' as *;

// 12 columns total grid
// Each column is 72px width
// Each column has 12px on both sides gutter
// Formula (12 columns × 72) + (12 gutters × 24) = 1152px total container width
// *Technically there are 11 gutters plus two halves on each side of the container.


// Variables
$grid-columns: 12; // total columns
$grid-gutter: 24px; // gap between columns

// Mixin: Grid container
@mixin generate-container {
  width: 96%; // 92% for .container - 100% for .container-fluid
  padding-left: calc($grid-gutter / 2);
  padding-right: calc($grid-gutter / 2);
  margin-left: auto;
  margin-right: auto;
}

// Mixin: Row
@mixin row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -(calc($grid-gutter / 2));
  margin-right: -(calc($grid-gutter / 2));
}

// Mixin: Columns
@mixin column($span, $columns: $grid-columns) {
  flex: 0 0 percentage($span / $columns);
  max-width: percentage($span / $columns);
  padding-left: calc($grid-gutter / 2);
  padding-right: calc($grid-gutter / 2);
}

// Mixin: Offset kolommen
@mixin offset($span, $columns: $grid-columns) {
  margin-left: percentage($span / $columns);
}


// Dynamisch genereren van kolom-klassen per breakpoint
@mixin generate-columns {
  @each $breakpoint, $min-width in $breakpoints {
    @if $min-width >0 {
      @media (min-width: $min-width) {
        @for $i from 1 through $grid-columns {
          .col-#{$breakpoint}-#{$i} {
            @include column($i);
          }
        }
      }
    }

    @else {

      // Voor xs (zonder media query)
      @for $i from 1 through $grid-columns {
        .col-xs-#{$i} {
          @include column($i);
        }
      }
    }
  }
}

// Genereren van offset-klassen
@mixin generate-offsets {
  @each $breakpoint, $min-width in $breakpoints {
    @if $min-width >0 {
      @media (min-width: $min-width) {
        @for $i from 1 through ($grid-columns - 1) {

          // Offset tot maximaal 11 (bij 12 kolommen)
          .col-#{$breakpoint}-offset-#{$i} {
            @include offset($i);
          }
        }
      }
    }

    @else {

      // Voor xs (zonder media query)
      @for $i from 1 through $grid-columns {
        .col-xs-offset-#{$i} {
          @include offset($i);
        }
      }
    }
  }
}


// SCSS Structuur
.container,
.container-fluid {
  @include generate-container;
}

.container {

  // Brede containers voor grotere schermen
  @each $breakpoint, $width in $breakpoints {
    @if $width >0 {
      @media (min-width: $width) {
        max-width: $width + 100px; // Bijvoorbeeld een containerbreedte
      }
    }
  }
}

.container-fluid {
  width: 100%;
}

.row {
  @include row;
}

.col {
  // flex: 1; // Default flexibele kolom
  flex: 1 0 0%;
  max-width: 100%;
  padding-left: calc($grid-gutter / 2);
  padding-right: calc($grid-gutter / 2);

  span {
    background-color: red;
    display: inline-block;
    height: 100%;
    width: 100%;
  }
}

// Voeg kolommen toe
@include generate-columns;

// Voeg offsets toe
@include generate-offsets;


.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}