// ----------------------------------------------------------
// Contains all the navigation styles.
// ----------------------------------------------------------

@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;


.navigation {
    display: flex;

    @include mq-only(xs) {
        flex-direction: column;

        &.open {
            height: 100%;
            width: 100%;
            background: $color-black--50;
            backdrop-filter: blur(12px);
            position: fixed;
            top: 0;
            left: 0;

            .menu {
                padding-top: 120px;

                .menu-list {
                    display: flex;
                    flex-direction: column;

                    li.menu-item {
                        margin-left: 0;
                        font-size: 2.25rem;
                    }
                }
            }

        }
    }

    @include mq(md) {
        align-items: center;
        justify-content: flex-end;
        gap: 32px;
        font-size: 14px;
    }

    .menu .menu-list {
        @include mq-only(xs) {
            display: none;
        }
    }

}

.menu-toggler {
    height: 40px;
    width: 40px;
    display: none;
    position: relative;
    padding: 15px 10px;
    border: 1px solid var(--color-text--16);
    border-radius: 360px;
    background: $color-white--16;
    cursor: pointer;

    @include mq-only(xs) {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
    }

    span {
        height: 1px;
        width: 100%;
        background-color: $color-white;
        transform-origin: center;
        transition: all 0.3s ease;
    }

    &.open {
        span {
            &:first-child {
                transform: translateY(3px) rotate(45deg);
            }

            &:last-child {
                transform: translateY(-4px) rotate(-45deg);
            }
        }
    }
}