// ----------------------------------------------------------
// Contains all SASS functions.
// ----------------------------------------------------------

// @function theme-color($key) {
// 	@return map-get($theme-dark-colors, $key)
// }



//
// Functions for calcluating spaces
// Inspired By: http://goo.gl/elchyz
//
// .css {
//  margin-top: quarter($base-space-unit); // => margin-top: 0.25em;
// }

@function quarter($value) {
	@return calc($value / 4);
}

@function halve($value) {
	@return calc($value / 2);
}


@function double($value) {
	@return calc($value * 2);
}

@function threefold($value) {
	@return calc($value * 3);
}

@function quadruple($value) {
	@return calc($value * 4);
}

// Source: https://jarv.is/notes/css-waving-hand-emoji/
@keyframes animHandWave {
	0% {
		transform: rotate(0deg);
	}

	10% {
		transform: rotate(15deg);
	}

	20% {
		transform: rotate(0deg);
	}

	30% {
		transform: rotate(15deg);
	}

	40% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(15deg);
	}

	60% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

@keyframes heartbeat {
	0% {
		transform: scale(.75);
	}

	20% {
		transform: scale(1);
	}

	40% {
		transform: scale(.75);
	}

	60% {
		transform: scale(1);
	}

	80% {
		transform: scale(.75);
	}

	100% {
		transform: scale(.75);
	}
}

@keyframes animFocusBlurInOut {
	0% {
		filter: blur(0px);
	}

	25% {
		filter: blur(4px); // Tussenliggende onscherpe focus
	}

	100% {
		filter: blur(0px); // In focus
	}
}

@keyframes animFocusBlurOut {
	0% {
		filter: blur(8px); // Begin met sterke onscherpte
	}

	25% {
		filter: blur(4px); // Minder onscherp, maar nog steeds een beetje wazig
	}

	50% {
		filter: blur(0px); // Tussenliggende focus (helderder)
	}

	75% {
		filter: blur(2px); // Lichte onscherpte, bijna in focus
	}

	100% {
		filter: blur(0px); // Volledig in focus
	}
}

@keyframes animArrowUp {
	0% {
		transform: translate(0);
	}

	to {
		transform: translate(100%, -100%);
	}
}

@keyframes animArrowDown {
	0% {
		transform: translate(-100%, 100%);
	}

	to {
		transform: translate(0);
	}
}

@keyframes shadowPulse {
	0% {
		box-shadow: 0 0 12px 0 #73E753;
		/* Begin met een kleinere blur */
	}

	50% {
		box-shadow: 0 0 20px 1px #73E753;
		/* Vergroot de blur naar een grotere waarde */
	}

	100% {
		box-shadow: 0 0 12px 0 #73E753;
		/* Keer terug naar de oorspronkelijke waarde */
	}
}