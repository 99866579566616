// cases.scss styling

// @use '../00-abstract/variables/_constants.scss' as *;
@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;


// case detail page
.case-hero {
    // height: 90vh; // 900px
    padding: 0;

    @include mq(sm) {
        height: 80vh;
    }

    .case-wrapper {
        height: 100%;
        width: 100%;

        .case-image {
            height: 100%;
            width: 100%;

            img {
                height: calc(100% + 96px);
                width: 100%;
                object-fit: cover;
            }
        }

        .case-content {
            height: 100%;
            width: 100%;
            @include center-element;
            @include flex-center;

            h1 {
                font-size: 160px; // hard fix
                line-height: 120%;
                color: #9CABFF;

                @include mq(sm) {
                    font-size: 64px;
                }

                span {
                    text-decoration: underline;
                }
            }
        }
    }
}

// case-detail
.case-detail {

    section:not(.case-intro) {
        // padding: 80px 0;
    }

    .case-intro {
        // @include mq(sm) {
        //     padding: 96px 0;
        // }

        // h2 {
        // 	margin-top: 96px;
        // }

        h4 {
            // color: $color-white--50;
        }

        .case-overview--credits {
            // @include mq(sm) {
            //     width: 100%;
            //     margin-bottom: 32px;
            // }

            // img {
            //     @include mq(sm) {
            //         height: 32px;
            //     }
            // }
        }

        .case-overview--tags {
            margin-top: 8px;
        }
    }

    .case-content {
        @include mq(sm) {
            margin-bottom: 40px;
        }

        .row {
            margin-bottom: 80px;

            @include mq(sm) {
                margin-bottom: 0;
            }
        }

        img {
            // border-radius: $border-radius--medium;
            width: 100%;
        }

        figcaption {
            margin-top: 12px;
        }

        h3 {
            margin-bottom: 8px;
        }
    }

    .mobile-tree {
        .row {
            display: flex;
            justify-content: space-between;

            @include mq(sm) {
                flex-direction: column;
            }

            div:last-child {
                margin-top: 40px;
            }

            // Reverse columns
            &.reverse {
                div:nth-child(1) {
                    order: 2;

                    @include mq(sm) {
                        order: 1;
                    }
                }

                div:nth-child(2) {
                    order: 1;

                    @include mq(sm) {
                        order: 2;
                    }
                }
            }

            ul.mobile-screens {
                // padding: 0;
                // display: flex;
                // flex-wrap: wrap;
                // justify-content: space-between;
                // gap: 24px;

                li {
                    width: calc(50% - 12px);
                    margin-bottom: 24px;
                    /* 50% breedte met 12px als ruimte tussen kolommen */
                    // margin-bottom: 24px; /* Ruimte tussen schermen onderaan */

                    &:nth-child(2) {
                        margin-top: 24%;

                        @include mq (sm) {
                            margin-top: 16%;
                        }
                    }

                    &:nth-child(2n) {
                        float: right;
                        clear: right;
                    }

                    &:nth-child(odd) {
                        clear: left;
                        float: left;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

        }

    }


    .case-media {
        .gallery-card {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

}

#rituals .case-intro {
    background-color: #0E182F;
    color: $color-white;
}

#euro2020 .case-intro {
    background-color: #4BBDCF;
    color: $color-white;
}

#klm .case-intro {
    background-color: #00A1DE;
    color: $color-white;
}