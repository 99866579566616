// ----------------------------------------------------------
// Contains all the button styles.
// ----------------------------------------------------------

@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;

.button {
    margin-top: 32px;
    padding: 8px 24px;
    border-radius: 360px;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    position: relative;
    
    text-transform: uppercase;
    font-weight: 600;

    .icon-arrow {
        height: 16px;
        width: 16px;
        display: inline-block;
        overflow: hidden;
        position: absolute;
        right: 24px;

        transition: 250ms ease-in-out;
        vertical-align: middle;
        opacity: 0; // Verbergt de pijl standaard
        transform: translateX(-8px); // Beginpositie iets naar links

        &::before,
        &::after {
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;

            background-image: url('../images/icons/icon-arrow-diagonal.svg');
            background-repeat: no-repeat;
            background-size: cover;

            filter: var(--color-invert);
        }

        &::after {
            transform: translate(-100%, 100%);
        }

    }

    &.primary {
        background: $color-primary;
        border: 1px solid $color-white--50;
        transition: 250ms ease-in-out;
    }

    &.secondary {
        background: $color-white--16;
        border: 1px solid var(--color-text--16);
        backdrop-filter: blur(8px);
        transition: 250ms ease-in-out;
    }

    &:hover {
        padding-right: 48px; // Maakt ruimte voor het icoon bij hover

        .icon-arrow {
            opacity: 1; // Maak het icoon zichtbaar
            transform: translateX(0); // Schuif het terug naar de originele positie
        }
    }
}