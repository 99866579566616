// ----------------------------------------------------------
// Contains the core basics styles.
// ----------------------------------------------------------

// @use '../00-abstract/variables/_constants.scss' as *;
@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;

/* Lenis recommendation */
html.lenis {
	height: auto;
}

.lenis.lenis-smooth {
	scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
	overscroll-behavior: contain;
}

.lenis.lenis-stopped {
	overflow: hidden;
}

.lenis.lenis-scrolling iframe {
	pointer-events: none;
}

// Define variables
:root {
	--color-background: #{$color-background}; // background color
	--color-text: #{$color-background--on}; // text color
	--color-text--50: #{$color-white--50}; // text color
	--color-text--16: #{$color-white--16}; // text color
	--color-invert: invert(0);
}

body {
	// Default theme color -- use variable
	background-color: var(--color-background);
	color: var(--color-text);

	&.use-cursor * {
		// cursor: none;
		cursor: default;

		a {
			cursor: pointer;
		}
	}

	&.no-scroll {
		overflow-y: hidden;
		position: fixed;
		height: 100%;
		width: 100%;

		@include mq(sm) {
			position: relative;
		}
	}

	&.no-scroll-y {
		overflow-y: hidden;
		height: 100%;
		width: 100%;
	}

	&[data-theme="light"],
	&[data-theme="dark"] {

		.parallax,
		.videoplayer-wrapper {
			color: $color-white;
		}
	}

	&[data-theme="light"] {
		// Call light theme
		// Change css variables for root
		--color-background: #{$color-background--on};
		--color-text: #{$color-background};
		--color-text--50: #{$color-black--50};
		--color-text--16: #{$color-black--16};
		--color-invert: invert(1);

		transition: all 0.5 linear;

		#site-footer::after {
			// background-color: #E95D2B; // #FFAE94
			background: radial-gradient(circle closest-corner at bottom, #E95D2B, transparent);
		}
	}

	&[data-theme="dark"] {
		// Call dark theme
		--color-background: #{$color-background};
		--color-text: #{$color-background--on};
		--color-text--50: #{$color-white--50};
		--color-text--16: #{$color-white--16};
		--color-invert: invert(0);

		transition: all 0.5 linear;

		#site-footer::after {
			// background-color: #0025FF;
			background: radial-gradient(circle closest-corner at bottom, rgba(0, 37, 255, 0.72), transparent);
		}
	}
}

section {
	position: relative;

	@include mq(xs) {
		padding: 80px 0;
	}

	@include mq(md) {
		padding: 160px 0;
	}

	.row:not(:last-child) {
		margin-bottom: 64px;
	}
}


//.gallery-card
.gallery-card {
	position: relative;
	@include flex-column;
	cursor: pointer;

	a {

		.gallery-card--media>*,
		.gallery-card--caption>* {
			cursor: pointer;
		}

		.gallery-card--caption {
			h4 {
				.arrow {
					height: 20px;
					width: 20px;
					overflow: hidden;
					// margin-left: 8px;
					position: relative;
					transition: 250ms ease-in-out;
					display: inline-block;
					vertical-align: middle;

					&::before,
					&::after {
						content: "";
						height: 100%;
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;

						background-image: url('../images/icons/icon-arrow-diagonal.svg');
						background-repeat: no-repeat;
						background-size: cover;

						filter: var(--color-invert);
					}

					&::after {
						transform: translate(-100%, 100%);
					}
				}
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include mq(xs) {
		margin-bottom: 40px;
	}

	@include mq(md) {
		&:nth-last-child(-n + 3) {
			margin-bottom: 0;
		}
	}
}

.featured {
	.gallery-card:not(:has(a)) {
		.gallery-card--caption {
			opacity: 0.7;
		}
	}

	.gallery-card a:hover {

		img {
			transform: scale(1.025);
		}

		.media--video {
			&::after {
				opacity: 0;
			}
		}

		.gallery-card--caption .arrow {

			&::before,
			&::after {
				animation-duration: .35s;
				animation-timing-function: cubic-bezier(.39, .575, .565, 1);
				animation-delay: 2ms;
			}

			&::before {
				animation-name: animArrowDown;
			}

			&::after {
				animation-name: animArrowUp;
			}
		}

		// .gallery-card--caption h5 {
		// 	transform: translateX(12px);

		// 	&::before {
		// 		transform-origin: right center;
		// 		transform: scaleX(0);
		// 	}
		// }
	}
}

.gallery-card--media {
	position: relative;
	overflow: hidden;
	border-radius: $border-radius--medium;
	// will-change: transform; // Safari fix border-radius

	img {
		position: relative;
		z-index: 1;
		transition: all .5s ease;
	}

	video {
		// height: calc(100% + 32px);
		// width: 100%;
		// object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		padding-left: inherit;
		padding-right: inherit;
		z-index: 0;
	}

	&.media--video {

		img {
			&:hover {
				opacity: 0;
			}
		}

		&::after {
			height: 24px;
			width: 24px;
			// pointer-events: none;
			transition: all .5s ease;

			content: '';
			position: absolute;
			top: 16px;
			right: 24px;
			z-index: 1;

			background-image: url('../images/icons/icon-play.svg');
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
}

.gallery-card--caption {
	@include flex-column;
	margin-top: 1rem;
	// margin-left: 2rem;

	flex-direction: row;
	// align-items: baseline;
	justify-content: space-between;

	h4,
	h5 {
		margin-bottom: 0 !important;
	}

	h4 {
		// text-transform: uppercase;
	}

	h5 {
		display: inherit;
		align-items: center;
		transition: all .5s ease;

		text-transform: initial;

		// &::before {
		// 	content: "";
		// 	display: inline-block;
		// 	height: 1px;
		// 	width: 2rem;
		// 	margin-right: 1rem;
		// 	background-color: $color-gray--dark;

		// 	transform-origin: left center;
		// 	transform: scaleX(1);
		// 	transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
		// 	z-index: 1;
		// }
	}
}

// svg {
// 	height: 100%;
// 	width: 100%;
// 	position: relative;

// 	// background-image: url('../images/cb-visual.jpg');
// 	// background-size: 200%;
// 	// background-position: 50% 62%;
// 	// clip-path: url(#cb-logo-mask);
// 	// -webkit-clip-path: url(#cb-logo-mask);


// 	image {
// 		// opacity: 50%;
// 		// @include center-element;
// 	}
// }

// .clipped {
// 	width: 100%; // was 500px
// 	height: 100%; // was 800px

// 	img {
// 		width: 150%;
// 		-webkit-clip-path: url(#my-clip-path);
// 		clip-path: url(#my-clip-path);

// 	}
// }


.marquee {
	// height: 100%;
	// margin: 0 auto;
	overflow: hidden;
	// position: relative;

	.marquee-track {
		display: flex;
		// width: fit-content;
		// flex: auto;
		flex-direction: row;

		.marquee-item {
			display: flex;
			align-items: center;
			flex-shrink: 0;
			flex-wrap: nowrap;
			
			@include mq(xs) {
				margin-left: 32px;
			}

			@include mq(md) {
				margin-left: 64px;
			}

			.marquee-item--title {
				&>* {
					line-height: 1.2;

					@include mq(xs) {
						font-size: 2rem; // 32px
					}

					@include mq(md) {
						font-size: 8.5rem; // 136px
					}
				}

				&+.marquee-item--icon {

					@include mq(xs) {
						margin-left: 32px;
					}

					@include mq(md) {
						margin-left: 64px;
					}
				}
			}

			.marquee-item--icon {
				background-color: $color-primary;
				color: $color-primary--on;
				border-radius: 100%;

				background-image: url('../images/icons/icon-play-black.svg');
				background-repeat: no-repeat;
				background-position: center;
				vertical-align: middle;

				@include mq(xs) {
					height: 64px;
					width: 64px;
					background-size: 16px;

					margin: 0 auto;
					display: block;
				}

				@include mq(md) {
					height: 120px;
					width: 120px;
					background-size: 32px;
				}
			}
		}
	}
}

.parallax {
	position: relative;

    .parallax-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -999;

        .parallax-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% + 120px);
            min-height: 100vh;
            background-image: url('../images/cb-profile-1.jpg');
            background-size: cover;
            background-position: center;
            transform: translateZ(0); // Zorgt ervoor dat de parallax effect goed werkt
            z-index: -1; // Zorg ervoor dat de afbeelding achter de tekst staat
            will-change: transform; // Optimalisatie voor de parallax effect
            transition: transform 0.2s; // Optionele transitie voor een vloeiende ervaring
        }
    }

    .content {
        text-align: center;
    }
}


.filmrol {
	padding: 80px 0;
	// overflow-x: auto;
	overflow: hidden;
	display: none;

	@include mq(sm) {
		padding: 32px 0;
	}

	&::after {
		content: "";
		height: 100%;
		width: 100%;
		background: linear-gradient(90deg, #01030A 0%, rgba(1, 3, 10, 0.00) 8%, rgba(1, 3, 10, 0.00) 92%, #01030A 100%);
		position: absolute;
		top: 0;
		left: 0;
	}

	.container-fluid {
		padding: 0;
	}


}

.roll {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;
	// gap: 8px;
	position: relative;
	// flex-direction: row-reverse;


	// new
	background-color: #101010;
	border: 1px solid $color-white--16;

	&.album {
		// padding: 0; // reset layout default padding
		padding: 8px;
		border-radius: 16px;

		li {
			// width: 20%;
			flex: 1; // equal widths
			border: 2px solid #101010;

			&:nth-child(5) {
				filter: url(#round);
				content: "";
				clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 20%, 75% 0);
			}

			@include mq(sm) {
				&:nth-last-child(-n+3) {
					display: none;
				}
			}

			&.cta {
				position: relative;
				border: none;

				&::after {
					height: 48px;
					width: 48px;

					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					content: "";
					display: inline-block;
					vertical-align: middle;

					background-image: url('../images/icons/icon-arrow-diagonal.svg');
					background-repeat: no-repeat;
					background-size: cover;
					transition: 250ms ease-in-out;
				}

			}
		}

		// &::after {
		// 	height: 16px;
		// 	width: 80px;
		// 	position: absolute;
		// 	left: 50%;

		// 	transform: translate(-50%,0%);

		// 	display: inline-block;
		// 	vertical-align: middle;

		// 	background-image: url('../images/film-code.svg');
		// 	background-repeat: no-repeat;
		// 	background-size: cover;
		// 	transition: 250ms ease-in-out;
		// 	mix-blend-mode: difference;
		// }
	}

	&::after {
		// height: 88px;
		// width: 440px;
		height: 40px;
		width: 100%;

		@include mq(sm) {
			height: 44px;
			width: 220px;
		}

		content: "";
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		background-image: url('../images/filmrol-start.png');
		background-repeat: no-repeat;
		// background-size: 100%;
		background-size: contain;
		background-position: top right;
	}

	li {
		flex: 0 0 auto;
		width: 25%;
		border-radius: 12px;
		overflow: hidden;
		position: relative;

		@include mq(sm) {
			width: 40%;
		}

		&::after {
			height: 32px;
			width: 112px;

			@include mq(sm) {
				height: 16px;
				width: 56px;
			}

			content: "";
			position: absolute;
			top: 8px;
			left: 8px;
			background-image: url('../images/film-code.png');
			background-repeat: no-repeat;
			background-size: 100%;
			mix-blend-mode: difference;

			display: none;
		}

		img {
			display: block;
			height: calc(100%);
			min-height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

}

.cursor {
	pointer-events: none;
	display: none;

	@include mq(md) {
		height: 10px;
		width: 10px;
		display: block;
		background-color: $color-white;

		position: fixed;
		top: 0;
		left: 0;

		border-radius: 50%;
		mix-blend-mode: difference;
		transform: translate(-50%, -50%);
		transition: width 500ms, height 500ms;

		z-index: 999;

		&.grow {
			width: 36px;
			height: 36px;
			cursor: pointer;
		}

		&.grow--big,
		&.grow--alt {
			width: 96px;
			height: 96px;
		}
	}
}





.filmcode {
	background-image: url('../images/filmcode-1.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	filter: var(--color-invert);

	@include mq(xs) {
		height: 24px;
	}

	@include mq(md) {
		height: 32px;
		margin-top: 24px;
	}

	&.small {
		height: 24px;
		width: 104px;
		display: inline-block;
	}

}


.paging {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%)
}

button {
	padding: 1rem 2rem 1rem 2rem;
	border: none;
	background: #555;
	color: white;
	text-transform: uppercase;
	font-weight: bold;
	z-index: 9999;
	cursor: pointer;

	&.prev {
		position: absolute;
		top: 10%;
		left: 15%;
	}

	&.next {
		position: absolute;
		top: 10%;
		right: 15%;
	}

	&.toggle {
		position: absolute;
		top: 10%;
	}
}


// .roll {
// 	width: 100%;
// 	display: flex;
// 	flex-wrap: nowrap;
// 	justify-content: flex-end;
// 	// gap: 8px;
// 	position: relative;
// 	// flex-direction: row-reverse;


// 	// new
// 	background-color: #101010;
// 	border: 1px solid $color-white--16;

// 	&.album {
// 		// padding: 0; // reset layout default padding
// 		padding: 8px;
// 		border-radius: 16px;

// 		li {
// 			// width: 20%;
// 			flex: 1; // equal widths
// 			border: 2px solid #101010;

.interactive-bg {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;

	canvas {
		display: block;
		opacity: 0.5;
	}
}


.focus {
	animation: animFocusBlurOut 1.75s ease-in-out;
}

.glossy {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	// background: rgb((255), 255, 255, 0.05);
	backdrop-filter: blur(100px);
	pointer-events: none;
}