// photography.scss styling

// @use '../00-abstract/variables/_constants.scss' as *;
@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;


html::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
}

#photography,
main.album {
    // .wrapper {
    //     height: 100%;
    //     padding: 0;
    //     display: flex;
    //     position: relative;
    //     // width: max-content;
    // }

}

#photography {
    // padding-top: 112px; // header padding
    position: relative;

    .wrapper {
        padding: 0;
        justify-content: center;

        .slider-vertical {
            height: 100%;

            .slide {
                height: 100vh;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                position: relative;

                .row {
                    padding: 24px 0 0;

                    @include mq(sm) {
                        padding: 0;
                    }
                }

                .films {
                    width: 100%;
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: flex-end;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;

                    // filmstock outline
                    .filmframes {
                        display: flex;
                        gap: 4px;

                        border: 2px solid var(--color-text--16);
                        border-right: none;
                        position: relative;
                        padding: 12px;
                        border-radius: 16px 0 0 16px;
                        background-color: var(--color-background);


                        @include mq(sm) {
                            padding: 8px;
                            border-radius: 12px 0 0 12px;
                        }

                        li.frame {
                            position: relative;
                            border-radius: 12px;
                            overflow: hidden;
                            position: relative;

                            &::after {
                                height: 100%;
                                width: 100%;
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-image: url('../images/kodak.png');
                                background-repeat: no-repeat;
                                background-size: cover;
                            }

                            @include mq-only(xs) {
                                &:nth-last-child(-n+2) {
                                    display: none;
                                }
                            }

                            img {
                                width: 100%;
                                display: block;
                                /*   transform: scale(0.9); */
                            }
                        }
                    }

                }

                .filmleader,
                .filmframes {
                    display: flex;
                    position: relative;
                }

                .filmleader {

                    .filmleader--left {
                        height: 100%;
                        width: 240px;

                        justify-content: flex-end;

                        @include mq(sm) {
                            min-width: 100%;
                            width: 120px;
                        }

                        .top {
                            height: 40px;
                            width: 100%;
                            position: relative;

                            @include mq(sm) {
                                height: 24px;
                            }

                            &:before,
                            &:after {
                                content: '';
                                position: absolute;
                                z-index: 1;
                            }

                            &:before {
                                top: 0;
                                left: 0px;
                                height: 42px;
                                width: 80px;
                                background-image: url('../images/film.svg');
                                background-repeat: no-repeat;
                                background-size: contain;
                                // opacity: 16%;
                                z-index: 1;
                                filter: var(--color-invert);
                            }

                        }

                        .bottom {
                            // padding: 72px;
                            border-radius: 0 16px 16px 0;
                            border-width: 2px;
                            border-left: none;
                            background-color: var(--color-background);

                            @include mq(sm) {
                                border-radius: 0 12px 12px 0;
                            }

                            &:after {
                                content: '';
                                background-color: var(--color-background);
                                position: absolute;
                                top: -2px;
                                left: 0;
                                height: 2px;
                                width: 80px;
                            }
                        }
                    }

                    // kodak after
                    .arrow {

                        &::after {
                            height: 48px;
                            width: 48px;
                            opacity: 64%;
                            filter: var(--color-invert);

                            @include mq(sm) {
                                height: 24px;
                                width: 24px;
                            }

                            @include center-element;

                            content: "";
                            display: inline-block;
                            vertical-align: middle;

                            background-image: url('../images/icons/icon-arrow-diagonal.svg');
                            background-repeat: no-repeat;
                            background-size: cover;
                            transition: 250ms ease-in-out;
                        }
                    }
                }

            }
        }
    }

    .bg-signature {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }
}


main.album {
    position: relative;

    @include mq(md) {
        height: 100vh;
        padding-top: 112px; // padding header
        padding-bottom: 112px; // padding footer
    }

    // is .slider--horizontal
    .slider {
        padding: 0;

        @include mq(md) {
            height: 100%;
            display: flex;
            align-items: center;
            // padding: 0 12px; // space outside
            // background-color: red;
            position: relative;
            width: max-content;
        }

        .slider-wrapper {
            // background-color: red;

            @include mq(xs) {
                padding-left: 12px;
                padding-right: 12px;
            }

            @include mq(md) {
                height: 64vh;
                display: flex;
                width: max-content;

                // based on col scss style
                padding-left: 12px;
                padding-right: 24px;

                // position: relative;
                // padding: 0 6%;
                // pointer-events: none;
            }

            .filmleader,
            .filmframes {
                display: flex;
                flex-direction: column; // only for mobile devices

                @include mq(md) {
                    height: 64vh;
                    min-height: 600px;
                    flex-direction: row; // only for desktop devices
                }

            }

            .filmleader {
                @include mq(xs) {
                    margin-bottom: 24px;
                }

                @include mq(md) {
                    margin-bottom: 0;
                    margin-right: 8px;
                }

                .filmleader--left,
                .filmleader--right {
                    // width: 50%;
                }

                .filmleader--left {
                    display: flex;
                    overflow: hidden;
                    position: relative;
                    flex-direction: row; // only for mobile devices

                    // @include mq(xs) {
                    //     min-width: 100%;
                    // }

                    @include mq(md) {
                        height: 100%;
                        min-width: 752px;
                        margin-right: 8px;

                        flex-direction: column; // only for desktop devices
                    }

                    .top,
                    .bottom {
                        display: flex;
                    }

                    .top {
                        @include mq(xs) {
                            display: none; // only for mobile devices
                        }

                        @include mq(md) {
                            display: flex; // only for desktop devices
                            height: 141px;
                            width: 100%;
                            position: relative;
                        }

                        &:before,
                        &:after {
                            content: "";
                            position: absolute;
                            z-index: 1;
                        }

                        &:before {
                            height: 145px;
                            width: 280px;
                            top: 0;
                            right: 0px;
                            background-image: url('../images/filmstrip-top.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-position: right;
                            opacity: 0.20;
                            z-index: 1;
                            filter: var(--color-invert);
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: -4px;
                            left: 0;

                            height: 4px;
                            width: 100%;
                        }

                    }

                    .bottom {
                        position: relative;

                        flex-grow: 1;
                        flex-direction: column;
                        justify-content: center; // Horizontaal centreren van de inhoud

                        @include mq(xs) {
                            // flex-grow: inherit;
                            padding: 96px 0;
                            text-align: center;
                        }

                        @include mq(md) {
                            text-align: left;
                            padding: 72px;
                            // background-color: #101010;
                            border-radius: 16px 0 16px 16px;
                            border-width: 4px;


                            // background-color: #101010;
                            border-radius: 16px 0 16px 16px;
                            border-style: solid;
                            border-color: var(--color-text--16);

                            // border-left: none;
                            // border-top: none;
                            // background-color: red;

                        }

                        &:after {
                            content: '';
                            background-color: var(--color-background);
                            position: absolute;
                            top: -4px;
                            right: 0;

                            height: 4px;
                            width: 276px;
                        }

                        h1 {
                            margin-bottom: 24px;
                        }

                        p {
                            color: var(--color-text--50);
                        }

                    }
                }

                .filmleader--right {
                    background: black; // black
                    border-radius: $border-radius--small;
                    overflow: hidden;
                    position: relative;

                    // @include mq(xs) {
                    //     // height: auto;
                    //     margin-bottom: 8px;
                    //     // width: 96%;
                    // }

                    @include mq(md) {
                        height: 100%; // Set the height of the element to 100% of the parent div
                    }


                    // &::after {
                    //     height: 100%;
                    //     width: 100%;
                    //     content: '';
                    //     position: absolute;
                    //     top: 0;
                    //     left: 0;
                    //     background-image: url('../images/kodak.png');
                    //     background-repeat: no-repeat;
                    //     background-size: cover;
                    // }

                    img {
                        // @include mq(xs) {
                        //     height: auto;
                        //     width: 100%;
                        // }

                        @include mq(md) {
                            height: 100%;
                            /* Set the height of the element to 100% of the parent div */
                            width: auto;
                            max-width: initial;
                        }
                    }
                }
            }

            .filmframes {
                @include mq(xs) {
                    gap: 24px;
                }

                @include mq(md) {
                    gap: 8px;
                }

                // @include mq(xs) {
                //     flex-direction: row; // Change to row for mobile devices
                //     flex-wrap: wrap; // Ensure items wrap if they exceed the width
                //     justify-content: center; // Horizontaal centreren van de inhoud
                //     margin-bottom: 32px;
                // }

                @include mq(md) {
                    // height: 100%; /* Set the height of the element to 100% of the parent div */
                    // align-items: center; // align items in center
                    // width: inherit;
                }

                li.frame {
                    overflow: hidden;
                    border-radius: $border-radius--small;

                    // @include mq(xs) {
                    //     height: auto;
                    //     width: 100%;
                    // }

                    @include mq(md) {
                        height: 100%; // Set the height of the element to 100% of the parent div
                        background: black; // black
                        position: relative;
                    }

                    // &::after {
                    //     height: 100%;
                    //     width: 100%;
                    //     content: '';
                    //     position: absolute;
                    //     top: 0;
                    //     left: 0;
                    //     background-image: url('../images/kodak.png');
                    //     background-repeat: no-repeat;
                    //     background-size: cover;
                    // }

                    &.half {
                        height: 50%;
                        margin-left: 17.5rem;
                    }

                    img {
                        // @include mq(xs) {
                        //     height: auto;
                        //     width: 100%;
                        // }

                        @include mq(md) {
                            height: 100%; // Set the height of the element to 100% of the parent div
                            width: auto;
                            max-width: initial;
                        }
                    }
                }
            }
        }

        &.grid {
            .filmframes {
                flex-wrap: wrap;
                height: auto;
                gap: 0;

                .frame {
                    height: auto !important;
                    width: 25%;

                    img {
                        height: auto !important;
                        width: 100% !important;
                    }
                }
            }
        }

    }
}

.view-toggle {

    @include mq(md) {
        // display: none;

        li {
            cursor: pointer;

            &.view-slider {
                height: 24px;
                width: 40px;
                background-image: url('../images/icon-filmrol.svg');
                background-repeat: no-repeat;
                // background-size: contain;
                opacity: 0.5;
            }

            &.view-grid {
                height: 24px;
                width: 20px;
                background-image: url('../images/icon-single.svg');
                background-repeat: no-repeat;
                // background-size: contain;
                opacity: 0.5;
            }

            &.active {
                opacity: 1;
            }
        }
    }
}

.filter {
    display: none;
}