// ----------------------------------------------------------
// Contains all the footer styles.
// ----------------------------------------------------------

@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;

footer {
	&#site-footer {
		position: relative;
		overflow: hidden;
		text-align: center;

		@include mq(xs) {
			padding: 96px 0;
		}

		@include mq(md) {
			padding-top: 120px;
			padding-bottom: 80px;
		}

		@include mq(xxl) {
			min-height: 100vh;
			padding-top: 280px;
			padding-bottom: 160px;
		}

		--footer-before-opacity: 1;

		&::after {
			content: "";
			width: 200vw;
			height: 100vw;
			border-radius: 100vw 100vw 0 0;

			background-color: transparent;
			opacity: var(--footer-before-opacity);

			position: absolute;
			bottom: -20%;
			left: 50%;
			transform: translateX(-50%);

			z-index: -1;

			@include mq(md) {
				width: 100vw;
				height: 50vw;
			}
		}

		.row:not(:last-child) {
			margin-bottom: 32px;
		}

		.footer--top {
			position: relative;
			min-height: 280px;

			@include mq(md) {
				min-height: 496px;
			}

			&::before {
				height: 100%;
				width: 100%;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				content: "";

				background-image: url('../images/logotype.svg');
				background-repeat: no-repeat;
				background-size: auto 104px;
				background-position: center;

				filter: var(--color-invert);

				@include mq(md) {
					background-size: auto 298px;
				}
			}

			.row {
				&:nth-child(1) {
					figure:nth-child(1) {}

					figure:nth-child(2) {
						display: none;

						@include mq(md) {
							display: block;
							// margin-top: 236px;
							transform: translateY(168px);
						}
					}
				}

				&:nth-child(2) {
					figure:nth-child(1) {
						// margin-top: 0;
						transform: translateY(12px);


						@include mq(md) {
							// margin-top: 0;
							transform: translateY(64px);

						}
					}

					figure:nth-child(2) {
						// margin-top: 0;
						transform: translateY(-12px);

						@include mq(md) {
							// margin-top: 56px;
							transform: translateY(104px);
						}
					}
				}

				figure img {
					border-radius: $border-radius--small;
				}
			}
		}

		.footer--bottom {

			h6 {
				margin-bottom: 16px;
			}

			.copyright {
				color: $color-gray--light;
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		.timebased {

			&-message,
			&-icon {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}

	&#site-footer--controls {
		padding-top: 40px;
		padding-bottom: 40px;

		@include mq(xs) {
			position: relative;
			text-align: center;

			.view-toggle {
				display: none;
			}
		}

		@include mq(md) {
			width: 100%;
			position: fixed;
			bottom: 0;
			text-align: left;

			.view-toggle {
				// display: block;
			}
		}
	}
}