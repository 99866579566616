// ----------------------------------------------------------
// Contains the typography styles.
// ----------------------------------------------------------

@use '../00-abstract/' as *;
@use '../00-abstract/fonts' as *;

$font-primary: "proxima-nova", sans-serif;
$font-secondary: "Anton", sans-serif;

/* set base values */
$text-base-size: 1em;
$text-base-height: 1.5;
$text-scale-ratio: 1.2;


html {
	-webkit-font-smoothing: none;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	// font-family: $font-secondary;
	// text-transform: uppercase;
	// font-weight: $font-weight-bold;
	// line-height: $heading-line-height;
	font-weight: 400;
	// line-height: 1.2;

	// color: $color-text-primary;
	&:not(:last-child) {
		@include mq(xs) {
			margin-bottom: 20px;
		}

		@include mq(md) {
			margin-bottom: 32px;
		}
	}
}

h1,
h2,
h3 {

	// DISPLAY TEXT-STYLES
	&.display {

		&--large,
		&--medium,
		&--small {
			font-family: $font-secondary;
			font-weight: unset;
			text-transform: uppercase;
			line-height: 1.0;
		}

		&--large {

			@include mq(xs) {
				font-size: 120px;
			}

			@include mq(md) {
				font-size: 240px;
			}
		}

		&--medium {
			@include mq(xs) {
				font-size: 64px;
			}

			@include mq(md) {
				font-size: 120px;
			}
		}

		&--small {
			// font-size: 64px;

			@include mq(xs) {
				font-size: 52px;
			}

			@include mq(md) {
				font-size: 104px;
			}
		}
	}
}

// heading--large
h1 {
	line-height: 1.2;
	font-weight: 700;

	@include mq(xs) {
		font-size: 2.5rem; // 36px
	}

	@include mq(md) {
		font-size: 5rem; // 80px
	}
}

// heading--medium
h2 {
	// font-size: 2rem; // 32px
	font-family: $font-secondary;
	// font-weight: 600;
	line-height: 1.0;
	text-transform: uppercase;

	@include mq(xs) {
		font-size: 3rem; // 48px
	}

	@include mq(md) {
		font-size: 4.5rem; // 72px
	}
}

// heading--small
h3 {
	font-family: $font-secondary;
	line-height: 1.0;
	margin-bottom: 20px;
	text-transform: uppercase;

	@include mq(xs) {
		font-size: 2rem; // 32px
	}

	@include mq(md) {
		font-size: 3rem; // 48px
	}
}


h4,
h5,
h6 {}

// title--large
h4 {
	font-weight: bold;

	@include mq(xs) {
		font-size: 1.125rem; // 18px
	}

	@include mq(md) {
		font-size: 1.25rem; // 20px
	}
}

// title--medium
h5 {
	line-height: 1.2;

	text-transform: uppercase;
	color: $color-gray--dark;
	margin-bottom: 20px;

	@include mq(xs) {
		font-size: 0.75rem; // 12px
	}

	@include mq(md) {
		font-size: 1rem; // 16px
	}
}

// title--small
h6 {
	font-size: 0.75rem; // 16px
	font-weight: bold;
	text-transform: uppercase;
	color: $color-gray--light;
}


body,
p,
a {
	font-family: $font-primary;
	font-size: $text-base-size;
	line-height: $text-base-height;
}

p,
a {
	&:not(:only-of-type):not(:last-of-type) {
		margin-bottom: 24px;
	}

	&.body {

		// BODY TEXT-STYLES
		&--large {
			@include mq(xs) {
				font-size: 1.25rem; // 20px
				line-height: 24px;
			}

			@include mq(md) {
				font-size: 1.5rem; // 24px
				line-height: 32px;
			}
		}

		&--medium {
			@include mq(xs) {
				font-size: 1rem; // 16px
				line-height: 24px;
			}

		}

		&--small {
			font-size: 0.75rem; // 12px
			line-height: 16px;
		}
	}
}