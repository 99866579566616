// ----------------------------------------------------------
// Contains all the header styles.
// ----------------------------------------------------------

@use '../00-abstract/' as *;

header#site-header {
	width: 100%;
	padding-top: 20px;
	padding-bottom: 20px;

	@include mq(md) {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	&.hidden {}

	&.invert {
		filter: invert(1);
	}

	&.sticky {
		position: relative;

		@include mq(md) {
			position: fixed;
			z-index: 2;
		}
	}

	.logo {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.header-logo {
			display: inline-block;
			filter: blur(0); // Scherpgesteld
			transition: filter 0.5s ease;

			&:hover {
				animation: animFocusBlurInOut 1s ease;
			}

			.logo--icon,
			.logo--type {
				display: inline-block;
				vertical-align: middle;
			}

			.logo--icon img {
				height: 24px;
				filter: var(--color-invert);

				@include mq(md) {
					height: auto;
				}
			}

			.logo--type {
				display: none;

				@include mq(md) {
					display: inline-block;
					margin-left: 8px;

					span {
						line-height: 16px;
						font-weight: bold;
						text-transform: uppercase;
						display: block;
					}
				}

			}
		}
	}

	.header-logo {
		z-index: 999;
	}

	.navigation {
		z-index: 998;
	}

	.status,
	.mode-toggler {
		display: none;

		@include mq(md) {
			display: inline-flex;
			align-items: center;
			padding: 6px 16px 6px 12px;
			border: 1px solid var(--color-text--16);
			border-radius: 360px;
			background: $color-white--4;
			backdrop-filter: blur(8px);
			transition: 250ms ease-in-out;
		}
	}

	.status {
		gap: 8px;
		font-size: 14px;
		text-transform: uppercase;
		cursor: pointer;

		@include mq(md) {
			display: inline-flex;
		}

		&:hover {
			background: $color-white--8;
		}

		.status-icon,
		p {
			cursor: inherit;
		}

		.status-icon {
			aspect-ratio: 1 / 1;
			overflow: hidden;
			position: relative;
			background-color: $color-gray--light;
			width: 6px;
			border-radius: 360px;
			will-change: transform;
		}

		&.available {
			.status-icon {
				background-color: #73E753;
				box-shadow: 0 0 12px 0 #73E753;
				animation: shadowPulse 2s infinite ease-in-out;
			}
		}

		&.non-available {
			.status-icon {
				background-color: #e75353;
				box-shadow: 0 0 12px 0 #e75353;
			}
		}
	}

	.mode-toggler,
	.mode-toggler {
		cursor: pointer;
	}

	.mode-toggler {
		&:hover {
			background: var(--color-text--16);
		}

		.mode-message {
			display: none;
		}

		@include mq(md) {

			.mode-message,
			.mode-icon {
				display: inline-block;
				vertical-align: middle;
			}

		}

		.mode-icon {
			height: 16px;
			width: 16px;
			background-image: url('../images/icons/icon-state-bluehour.svg');
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			vertical-align: middle;
			cursor: pointer;
			filter: var(--color-invert);

			@include mq(md) {
				margin-right: 8px;
			}

			&.--day {
				background-image: url('../images/icons/icon-time-morning.svg');
			}

			&.--night {
				background-image: url('../images/icons/icon-time-night.svg');
			}
		}
	}
}

#case-page #site-header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
}

#about-page #site-header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
}