// homepage.scss styling

// @use '../00-abstract/variables/_constants.scss' as *;
@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;


// Resource: https://jamesmcgrath.net/scaling-svg-clippath/


// section: hero
.hero {
    height: auto;
    padding-top: 48px;
    position: relative;
    padding-bottom: 80px;

    @include mq(md) {
        padding-bottom: 0;
    }

    .hero-image {
        width: 100%;
        height: 100%;
        position: relative;

        .cb-mask {
            width: 100%;
            padding-top: 62.22%;
            position: relative;
            clip-path: url(#slice1);
            overflow: hidden;
            // background: white;

            .image {
                height: auto;
                width: 100%;

                @include mq(xs) {
                    position: absolute;
                    top: 80%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                @include mq(md) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            img,
            video {
                height: calc(100% + 120px); // Used for parallax
                min-height: 100vh;
                // height: 100%;
                width: 100%;
                display: block;
                object-fit: cover;
            }
        }

        .hero-image--content {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0%;
            left: 0%;

            display: flex;
            align-items: center;
            justify-content: center;

            h2 {
                text-transform: uppercase;

                font-family: "termina", sans-serif;
                font-size: 12px;
                font-weight: 700;
                font-style: normal;

                margin-top: 10%;

                span {
                    font-family: "termina", sans-serif;
                    font-weight: 500;
                    font-style: normal;
                }

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }
    }

    .hero-hint {
        margin-top: 80px;
        text-align: center;
        z-index: 5;

        .filmcode {
            margin-bottom: 16px;
        }
    }

}


// SECTION: .intro
.intro {}


// SECTION: .about
.about {
    // position: relative;

    // .about-content {
    //     z-index: 1;
    // }

}

.bg-signature {
    // z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.75;
    z-index: -999;

    svg {
        height: 100%;
        width: 100%;
        opacity: 0.24;
        // background: red;

        @include mq(sm) {
            opacity: 0.48;
            // display: none;
        }
    }

    img {
        height: inherit;
        margin-left: auto;
        margin-right: auto;
    }
}

// SECTION: .gallery 
.gallery {

    .row {
        // @include mq(xs) {
        //     :not(:last-child) {
        //         margin-bottom: 16px;
        //     }
        // }

        @include mq(md) {

            &:nth-child(1) {
                .gallery-card:nth-child(2) {
                    margin-top: 80px;
                }
            }

            &:nth-child(2) {
                .gallery-card:nth-child(2) {
                    margin-top: 120px;
                }
            }

            &:nth-child(3) {}

        }
    }
}


// .reel
.reel {
    // overflow: hidden; // mobile fix

    // .container {
    //     @include mq(sm) {
    //         width: 100%;
    //         display: unset;
    //         padding: 0;
    //         margin: 0;
    //     }
    // }

    .videoplayer {
        position: relative;

        .videoplayer-wrapper {
            position: relative;
            overflow: hidden;
            border-radius: $border-radius--medium;

            &.is-playing .videoplayer-overlay {
                opacity: 0;
                visibility: hidden;
            }

            .videoplayer-overlay {
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: $color-black--50;
                pointer-events: none;
                z-index: 1;

                display: flex;
                align-items: center;

                // color: $color-primary;
            }

            video {
                cursor: pointer;

                @include mq(sm) {
                    border-radius: 0;
                }
            }
        }
    }
}

.visuals {
    display: none;

    figure:first-child {
        margin-top: 120px;

        @include mq(sm) {
            margin-to: 0;
        }
    }

    .js-carousel {
        @include mq(sm) {
            margin-bottom: 40px;
        }
    }
}

.featured {
    .row {
        margin: 0;
    }
}

// SECTION: .photography
.photography {
    overflow: hidden;

    .menu {
        // opacity: 0;
        pointer-events: none;

        .list--vertical {
            .list-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                text-transform: uppercase;

                a {
                    display: flex;
                    position: relative;

                    .card-media {
                        width: 160px;
                        height: 220px;

                        position: absolute;
                        opacity: 0;
                        pointer-events: none;

                        @include mq(sm) {
                            width: 320px;
                            height: 440px;
                        }

                        .card-image--inner {
                            position: relative;
                            width: 100%;
                            height: 0;
                            padding-top: 125%;
                            overflow: hidden;
                            border-radius: 8px;

                            img {
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    .list-item-text {
                        position: relative;
                        // display: block;

                        font-family: "Anton";
                        font-size: 64px;
                        line-height: 112%;

                        @include mq(sm) {
                            font-size: 160px;
                        }

                        // padding: 2vh 0;
                        // opacity: 1;
                        opacity: 0.5;
                        -webkit-text-stroke-width: 1px;
                        -webkit-text-stroke-color: var(--color-text);
                        color: transparent;

                        transition: opacity 350ms ease;
                        white-space: nowrap;
                        overflow: hidden;
                        // cursor: pointer;

                        .list-item-innertext {
                            display: inline-block;
                            cursor: pointer;
                        }

                        &:hover,
                        &.active {
                            opacity: 1; // 0.85
                            color: var(--color-text);
                            filter: var(--color-invert);
                            -webkit-text-stroke-color: transparent;
                        }
                    }
                }
            }
        }
    }


    .menu:hover {
        .list-item-text {
            opacity: 0.25;

            // &:hover {
            // 	// opacity: 0.85;
            // 	opacity: 1;
            // 	color: $color-white;
            // 	-webkit-text-stroke-color: transparent;
            // }
        }
    }

    .list-item:hover {
        .list-item-text {
            z-index: 10; // behind hovered text
            mix-blend-mode: difference;
        }

        .card-media {
            z-index: 2;
        }
    }

}