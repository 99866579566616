// ----------------------------------------------------------
// Contains the reset styles rules.
// ----------------------------------------------------------

/* Box sizing rules  */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0 none;
  outline: 0;
  list-style: none;
  font-size: 100%;
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0;
  padding: 0;
}

/* Remove list styles */
ol[role='list'],
ul[role='list'] {
  list-style: none;
}

/* Remove list padding if list is part of a nav */
nav ul,
nav ol {
  padding: 0;
}

/* Set body rules */
body {
  // min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Set image and video rules */
img,
svg,
picture,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Inherit fonts */
input,
button,
textarea,
select {
  font: inherit;
}

hr {
  height: 0;
  box-sizing: content-box;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
}

button,
input,
input[type="button"],
input[type="reset"],
input[type="submit"],
select {
  appearance: none;
  border-radius: 0;
}

button[disabled],
input[disabled] {
  cursor: default;
}