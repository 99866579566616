// ----------------------------------------------------------
// Contains all the links styles.
// ----------------------------------------------------------
@use '../00-abstract/' as *;
@use '../00-abstract/functions' as *;

a {
	color: inherit;
	text-decoration: none;
	transition: color 0.1s linear;

	&:link,
	&:visited {
		color: inherit;
	}

	&:hover,
	&:active {
		color: inherit;
	}

	&.tertiary {
		&:link,
		&:visited {
			color: $color-gray--light;
		}

		&:hover,
		&:active {
			color: $color-white;
		}
	}

	&.link--mail {
		position: relative;
		font-size: 1.5rem; // 24px
		font-weight: 600;
		color: var(--color-text);

		&::before {
			height: 1px;
			width: 100%;
			background: $color-white;
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;

			will-change: transform, color;
			transform: scaleX(0);
			transform-origin: 100% 50%;
			transition: transform .35s;
			transition: transform .35s, transform .35s;
			transition-timing-function: cubic-bezier(.25, .46, .45, .94);
		}

		&:hover {
			.arrow {

				&::before,
				&::after {
					animation-duration: .35s;
					animation-timing-function: cubic-bezier(.39, .575, .565, 1);
					animation-delay: 2ms;
				}

				&::before {
					animation-name: animArrowDown;
				}

				&::after {
					animation-name: animArrowUp;
				}
			}

			&::before {
				transform: scaleX(1);
				transform-origin: 0 50%;
				transition-timing-function: ease;
			}
		}

		.arrow {
			height: 20px;
			width: 20px;
			overflow: hidden;
			// margin-left: 8px;
			position: relative;
			transition: 250ms ease-in-out;
			display: inline-block;
			vertical-align: middle;

			&::before,
			&::after {
				content: "";
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;

				background-image: url('../images/icons/icon-arrow-diagonal.svg');
				background-repeat: no-repeat;
				background-size: cover;

				filter: var(--color-invert);
			}

			&::after {
				transform: translate(-100%, 100%);
			}
		}
	}

	&.link--inline {
		&::after {
			content: "";
			height: 16px;
			width: 16px;
			margin-left: 4px;

			display: inline-block;
			vertical-align: middle;

			background-image: url('../images/icons/icon-arrow-diagonal.svg');
			background-repeat: no-repeat;
			background-size: cover;
			transition: 250ms ease-in-out;

			filter: var(--color-invert);
		}
	}
}