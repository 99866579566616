// ----------------------------------------------------------
// Contains all SASS mixins.
// ----------------------------------------------------------

@use './variables/constants' as *;
@use './variables/variables' as *;


// Font Face
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?iefix') format('eot'),
    url($file-name + '.woff') format('woff'),
    url($file-name + '.ttf') format('truetype'),
    url($file-name + '.svg##{$font-name}') format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

// Media query
// A map of breakpoints.
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);


// Setup mediaqueries == @include mq(sm);
@mixin mq($breakpoint, $query: min-width, $type: 'screen') {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {

    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint );

    // Write the media query.
    @media #{$type} and (#{$query}: #{$breakpoint-value}) {
      @content;
    }

  }

  // If the breakpoint doesn't exist in the map.
  @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }

}

// Mixin voor een mediaquery die alleen geldt voor een specifiek breakpoint (zonder overlap)
@mixin mq-only($breakpoint, $type: 'screen') {

  // Als het breakpoint bestaat in de map
  @if map-has-key($breakpoints, $breakpoint) {

    // Haal de waarde van het breakpoint op
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Controleer welk breakpoint wordt gebruikt en pas de juiste logica toe
    @if $breakpoint =='xs' {
      // Voor xs, target alleen schermen kleiner dan sm, gebruik max-width
      $sm-min: map-get($breakpoints, 'sm');

      @media #{$type} and (max-width: #{$sm-min - 1px}) {
        @content;
      }
    }

    @else if $breakpoint =='sm' {
      // Voor sm, target alleen schermen tussen sm en md
      $sm-min: map-get($breakpoints, 'sm');
      $md-min: map-get($breakpoints, 'md');

      @media #{$type} and (min-width: #{$sm-min}) and (max-width: #{$md-min - 1px}) {
        @content;
      }
    }

    @else if $breakpoint =='md' {
      // Voor md, target alleen schermen tussen md en lg
      $md-min: map-get($breakpoints, 'md');
      $lg-min: map-get($breakpoints, 'lg');

      @media #{$type} and (min-width: #{$md-min}) and (max-width: #{$lg-min - 1px}) {
        @content;
      }
    }

    @else if $breakpoint =='lg' {
      // Voor lg, gebruik min-width voor schermen groter dan of gelijk aan lg
      $lg-min: map-get($breakpoints, 'lg');
      $xl-min: map-get($breakpoints, 'xl');

      @media #{$type} and (min-width: #{$lg-min}) and (max-width: #{$xl-min - 1px}) {
        @content;
      }
    }

    @else if $breakpoint =='xl' {
      // Voor xl, gebruik min-width voor schermen groter dan of gelijk aan xl
      $xl-min: map-get($breakpoints, 'xl');

      @media #{$type} and (min-width: #{$xl-min}) {
        @content;
      }
    }

    @else {
      @warn 'Invalid breakpoint for mq-only: #{$breakpoint}.';
    }

  }

  @else {
    // Als het breakpoint niet bestaat in de map
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }

}

// Align content
@mixin align($align: center) {
  @if ($align ==center) {
    margin-left: auto;
    margin-right: auto;
  }
}

// Vetical align
@mixin vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// Flex box
@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

@mixin flex-center-vert {
  display: flex;
  align-items: center;
}

@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}


// Background cover
@mixin cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


@mixin center-element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin center-element-x {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
}

// // Spacing: 8 pixel grid
// @mixin spacing($value) {
//   // Definieer mixins voor verschillende spacing-waarden
//   @if $value == xs {
//     margin: $spacing-unit / 4;
//   } @else if $value == s {
//     margin: $spacing-unit / 2;
//   } @else if $value == m {
//     margin: $spacing-unit;
//   } @else if $value == l {
//     margin: $spacing-unit * 2;
//   } @else if $value == xl {
//     margin: $spacing-unit * 3;
//   } @else {
//     // Standaardwaarde voor niet-herkende waarden
//     margin: $spacing-unit;
//   }
// }


// /* ===== Usage ===== */
// div {
//   background-image: url("cute-doggo.png");
//   @include cover-background;
// }



// $grid-columns: 12;
// $grid-gutter-width: 30px;

// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// );

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px
// );


// @mixin grid($cols, $gutter) {
//   float: left;
//   margin-right: $gutter;
//   margin-bottom: $gutter;
//   width: 100%;
// }


// Declare total columns
// Declare gutter - between columns