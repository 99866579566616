// ----------------------------------------------------------
// Contains all SASS & CSS variables.
// ----------------------------------------------------------


// Space unit
$base-space-unit: 40px;

// border radius
$border-radius--xs: 4px;
$border-radius--small: 8px;
$border-radius--medium: 12px;
$border-radius--large: 20px;
